import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldMapper,
  useFieldSetter,
  useFieldValidation,
  useFieldValue,
} from '../../common/utils/forms';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useCallback } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { saleFormContext } from '../SaleFields';
import { SaleClientFields_InTheCareOfFragment$key } from './__generated__/SaleClientFields_InTheCareOfFragment.graphql';
import { SaleClientFields_OrderNumberFragment$key } from './__generated__/SaleClientFields_OrderNumberFragment.graphql';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { useSuggestions } from '../useSuggestions';
import { SuggestionPromptInput } from '../../common/components/__generated__/SuggestionsFakeQuery.graphql';
import {
  AutomaticSuggestionList,
  emptySuggestionPromptInput,
  scoreThreshold,
  suggestionCount,
  Suggestions,
} from '../../common/components/Suggestions';
import { SaleClientFields_ClientInTheCareOfInput_SuggestionsFragment$key } from './__generated__/SaleClientFields_ClientInTheCareOfInput_SuggestionsFragment.graphql';
import { SaleClientFields_ClientInTheCareOf_SuggestionsQuery } from './__generated__/SaleClientFields_ClientInTheCareOf_SuggestionsQuery.graphql';
import { RequiredForInputLabel, RequiredForStatus } from '../RequiredForJobStatus';
import { JobStage } from '../jobStage';

const fieldInTheCareOfKey = createFieldKey<string>();

export function useFieldInTheCareOfRead($key: SaleClientFields_InTheCareOfFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleClientFields_InTheCareOfFragment on ISaleClient {
        inTheCareOf
      }
    `,
    $key,
  );
  const inTheCareOf = useFieldValue(saleFormContext, fieldInTheCareOfKey, () => $data?.inTheCareOf ?? '');

  const useMapper = useFieldMapper(saleFormContext, fieldInTheCareOfKey);
  useMapper((v) => ({ client: { inTheCareOf: v || null } }), [], 'save');

  return { inTheCareOf };
}
export function useFieldInTheCareOf($key: SaleClientFields_InTheCareOfFragment$key | null | undefined, disabled: boolean) {
  const { inTheCareOf, ...rest } = useFieldInTheCareOfRead($key);
  const setInTheCareOf = useFieldSetter(saleFormContext, fieldInTheCareOfKey);

  const useValidation = useFieldValidation(saleFormContext, fieldInTheCareOfKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const renderInTheCareOf = useCallback(
    (input$key: SaleClientFields_ClientInTheCareOfInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => (
      <ClientInTheCareOfInput $key={input$key} value={inTheCareOf} setValue={setInTheCareOf} saleKind={saleKind} disabled={disabled} />
    ),
    [disabled, setInTheCareOf, inTheCareOf],
  );

  return { inTheCareOf, setInTheCareOf, renderInTheCareOf, ...rest };
}
function ClientInTheCareOfInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: SaleClientFields_ClientInTheCareOfInput_SuggestionsFragment$key | null | undefined;
  value: string;
  setValue: SetValueFn<string>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldInTheCareOfKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldInTheCareOfKey);

  const $data = useFragment(
    graphql`
      fragment SaleClientFields_ClientInTheCareOfInput_SuggestionsFragment on ISale
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <TextField
      error={hasErrors}
      label={<RequiredForInputLabel label={t('field.client.inTheCareOf')} requiredFor={requiredFor} disabled={disabled} />}
      data-label-key='field.client.inTheCareOf'
      value={value}
      onChange={handleChange}
      disabled={disabled}
      inputProps={{ maxLength: 50 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Suggestions
              disabled={disabled}
              content={(close) => (
                <ClientInTheCareOfSuggestions
                  suggestionPromptInput={suggestionPromptInput}
                  onChange={(v) => {
                    setValue(v);
                    close();
                  }}
                />
              )}
            />
          </InputAdornment>
        ),
      }}
      helperText={message ? t(message, { ns: 'common', ...errorParams }) : ''}
    />
  );
}

function ClientInTheCareOfSuggestions({
  onChange: handleChange,
  suggestionPromptInput,
}: {
  onChange: (selectedValue: string) => void;
  suggestionPromptInput: SuggestionPromptInput | null;
}) {
  const results = useLazyLoadQuery<SaleClientFields_ClientInTheCareOf_SuggestionsQuery>(
    graphql`
      query SaleClientFields_ClientInTheCareOf_SuggestionsQuery(
        $scoreThreshold: Float!
        $suggestionPrompt: SuggestionPromptInput!
        $suggestionCount: Int!
      ) {
        suggestedInTheCareOf(scoreThreshold: $scoreThreshold, prompt: $suggestionPrompt, count: $suggestionCount) {
          value
          score
        }
      }
    `,
    {
      scoreThreshold: scoreThreshold,
      suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
      suggestionCount: suggestionCount,
    },
  );

  const data = results.suggestedInTheCareOf ?? [];

  return (
    <AutomaticSuggestionList keySelector={(v) => `${v}`} items={data} onChange={(selectedValue: string) => handleChange(selectedValue)} />
  );
}

const fieldOrderNumberKey = createFieldKey<string>();

export function useFieldOrderNumber(
  $key: SaleClientFields_OrderNumberFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleClientFields_OrderNumberFragment on ISaleClient {
        orderNumber
      }
    `,
    $key,
  );

  const [orderNumber, setOrderNumber] = useField(saleFormContext, fieldOrderNumberKey, () => $data?.orderNumber ?? '');
  const useMapper = useFieldMapper(saleFormContext, fieldOrderNumberKey);
  useMapper((v) => ({ client: { orderNumber: v || null } }), [], 'save');

  const useValidation = useFieldValidation(saleFormContext, fieldOrderNumberKey);
  useValidation((val) => (required ? val.length > 0 : true), [required], 'transferable:required');

  const renderOrderNumber = useCallback(
    () => <ClientOrderNumberInput value={orderNumber} setValue={setOrderNumber} disabled={disabled} required={required} />,
    [orderNumber, setOrderNumber, disabled, required],
  );

  return { orderNumber, setOrderNumber, renderOrderNumber };
}
function ClientOrderNumberInput({
  value,
  setValue,
  disabled,
  required,
}: {
  value: string;
  setValue: SetValueFn<string>;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setValue(e.target.value);
    },
    [setValue],
  );
  const hasErrors = useFieldHasErrors(saleFormContext, fieldOrderNumberKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldOrderNumberKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = required
    ? {
        serviceCall: ['transfer'],
      }
    : {};

  return (
    <TextField
      label={<RequiredForInputLabel label={t('field.client.orderNumber')} requiredFor={requiredFor} disabled={disabled} />}
      data-label-key='field.client.orderNumber'
      value={value}
      onChange={handleChange}
      disabled={disabled}
      inputProps={{ maxLength: 20 }}
      error={hasErrors}
      helperText={message && t(message, { ...errorParams })}
    />
  );
}
