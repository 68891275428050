import { Chip, Collapse, IconButton, InputAdornment, Paper, SxProps, TextField, Theme, useMediaQuery, useTheme } from '@mui/material';
import { CraneCapacityAutocomplete } from '../common/components/CraneCapacityAutocomplete';
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { DateTime } from 'luxon';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange, MobileDateRangePicker, SingleInputDateRangeField, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { CustomActionBar } from '../common/components/MuiDatePicker';
import { dateFormat } from '../common/utils/dateTimeUtils';
import { useTranslation } from 'react-i18next';
import { ForwardRepresentativeAutocompleteProps, RepresentativeAutocomplete } from '../common/components/RepresentativeAutocomplete';
import { EquipmentKindAutocomplete, ForwardEquipmentKindAutocompleteProps } from '../common/components/EquipmentKindAutocomplete';
import { DispatchBranchAutocomplete, ForwardDispatchBranchAutocompleteProps } from '../common/components/DispatchBranchAutocomplete';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { SelectPicker } from '../common/components/SelectPicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useSearchParams } from 'react-router';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { resolvedLanguage } from '../i18n';
import { SharedStateKey, useSharedState } from '../common/utils/sharedState';
import { appSharedStateContext } from '../AppSharedState';
import { useEffectEvent } from '../common/utils/effectUtils';
import { JobFilters_useJobFiltersFragment$key } from './__generated__/JobFilters_useJobFiltersFragment.graphql';
import { createSearchParam, ISearchParams } from '../common/utils/searchParams';
import { Value } from 'value-object-cache';

// Used to prevent re-render of the autocomplete using the vehicleIds (Capacity & ConfigurationKind)
const emptyVehicleIds: readonly string[] = [];

export function TextSearchFilter({
  value,
  placeHolder,
  onChange: handleChange,
}: {
  value: string;
  placeHolder: string;
  onChange: (value: string) => void;
}) {
  const theme = useTheme();

  return (
    <TextField
      sx={{ [theme.breakpoints.up('sm')]: { width: '24rem' } }}
      value={value}
      placeholder={placeHolder}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position='end'>
            <IconButton onClick={() => handleChange('')}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value)}></TextField>
  );
}

export function JobKindFilter<T extends string>({
  value,
  options,
  label,
  onChange: handleChange,
  sx,
}: {
  value: readonly T[];
  options: readonly T[];
  label: string;
  onChange: (kind: readonly T[]) => void;
  sx?: SxProps;
}) {
  const { t } = useAmbientTranslation();

  return (
    <SelectPicker
      multiple
      value={value}
      sx={sx}
      onChange={(_, newValue) => handleChange(newValue)}
      options={options}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`kind.${o}`)}
      textFieldProps={(params) => ({ ...params, label })}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option} onDelete={handleDelete} label={t(`kindShort.${option}`)} size='small' />;
        })
      }
    />
  );
}

export function JobStatusFilter<T extends string>({
  value,
  options,
  onChange: handleChange,
  label,
  renderChip,
}: {
  value: readonly T[];
  options: readonly T[];
  onChange: (status: readonly T[]) => void;
  label: string;
  renderChip: (status: T, onDelete: (event: unknown) => void) => ReactNode;
}) {
  const { t } = useAmbientTranslation();

  return (
    <SelectPicker
      multiple
      value={value}
      onChange={(_, status) => handleChange(status)}
      options={options}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`status.${o}`)}
      textFieldProps={(params) => ({ ...params, label })}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return renderChip(option, getTagProps({ index }).onDelete);
        })
      }
    />
  );
}

type CraneCapacityFilterProps = {
  value: readonly number[];
  onChange: (value: readonly { capacity: number }[]) => void;
  sx?: SxProps;
};

export function CraneCapacityFilter({ value, onChange: handleChange, sx }: CraneCapacityFilterProps) {
  const { t } = useTranslation('serviceCall');

  return (
    <CraneCapacityAutocomplete
      key='capacity'
      value={value.map((v) => ({ capacity: v, label: `${v}` }))}
      onChange={handleChange}
      sx={sx}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.capacity} onDelete={handleDelete} label={option.capacity} size='small' />;
        })
      }
      multiple
      vehicleIds={emptyVehicleIds}
      equipmentKindCode={null}
      configurationKindCode={null}
      textFieldProps={() => ({ label: t('field.equipment.capacity') })}
    />
  );
}

export function ArrivalDateFilter({
  value,
  onChange: handleChange,
  sx,
}: {
  value: { readonly start: DateTime<true>; readonly end: DateTime<true> } | null;
  onChange: (value: DateRange<DateTime>) => void;
  sx?: SxProps;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery('@media (pointer:fine)'); // https://mui.com/x/react-date-pickers/date-range-picker/ media query used by DateRangePicker to switch between Desktop and Mobile variant
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const today = DateTime.now();
  const shortcuts: PickersShortcutsItem<DateRange<DateTime>>[] = [
    {
      label: t('dateTime.sevenDays'),
      getValue: () => [today, today.plus({ day: 7 })],
    },
    {
      label: t('dateTime.fourteenDays'),
      getValue: () => [today, today.plus({ day: 14 })],
    },
    {
      label: t('dateTime.thirtyDays'),
      getValue: () => [today, today.plus({ day: 30 })],
    },
    {
      label: t('dateTime.sixtyDays'),
      getValue: () => [today, today.plus({ day: 60 })],
    },
  ];

  const pickerValue: DateRange<DateTime> = useMemo(() => (value ? [value.start, value.end] : [null, null]), [value]);

  const [focused, setFocused] = useState(false);
  const handleInputFocus = useCallback(() => setFocused(true), []);
  const handlePositionChange = useCallback(
    (v: DateRange<DateTime>) => {
      if (v[0] && v[1]) {
        setFocused(false);
      }
      handleChange(v);
    },
    [handleChange],
  );

  const handleClear = useCallback(() => handleChange([null, null]), [handleChange]);
  const handleToggleCalendar = useCallback(() => setFocused((prev) => !prev), []);

  if (isDesktop || largeScreen) {
    return (
      <>
        <SingleInputDateRangeField<DateTime>
          value={pickerValue}
          onChange={handleChange}
          label={t('list.column.date')}
          onFocus={handleInputFocus}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {value && (
                  <IconButton
                    onClick={handleClear}
                    sx={{
                      '.MuiTextField-root:not(:hover) &': {
                        display: 'none',
                      },
                    }}>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                )}
                <IconButton onClick={handleToggleCalendar}>
                  <CalendarMonthIcon fontSize='small' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Collapse in={focused}>
          <Paper sx={{ mt: '-1rem', pb: '1rem' }}>
            <StaticDateRangePicker<DateTime>
              value={pickerValue}
              onChange={handlePositionChange}
              calendars={1}
              sx={{ display: 'flex', flexDirection: 'row', ...sx }}
              slotProps={{ shortcuts: { items: shortcuts }, toolbar: { hidden: true }, actionBar: { actions: [] } }}
            />
          </Paper>
        </Collapse>
      </>
    );
  }

  return (
    <MobileDateRangePicker<DateTime>
      value={pickerValue}
      onChange={handleChange}
      label={t('list.column.date')}
      slots={{
        field: SingleInputDateRangeField,
        actionBar: CustomActionBar,
      }}
      slotProps={{
        ...(!compact ? { shortcuts: { items: shortcuts } } : {}),
        actionBar: { actions: ['cancel', 'clear', 'accept'] },
        toolbar: { hidden: true },
      }}
      format={dateFormat}
      sx={sx}
    />
  );
}

export function DispatchBranchFilter({
  value,
  onChange: handleChange,
  sx,
}: {
  value: NonNullable<ForwardDispatchBranchAutocompleteProps<true>['value']>;
  onChange: NonNullable<ForwardDispatchBranchAutocompleteProps<true>['onChange']>;
  sx?: SxProps;
}) {
  const { t, i18n } = useAmbientTranslation();
  const lang = resolvedLanguage(i18n);

  return (
    <DispatchBranchAutocomplete
      value={value}
      onChange={handleChange}
      sx={sx}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={option.label?.[lang] ?? ''} size='small' />;
        })
      }
      textFieldProps={() => ({ label: t('field.project.branch.dispatchBranch') })}
      multiple
    />
  );
}

export function EquipmentKindFilter({
  value,
  onChange: handleChange,
  sx,
}: {
  value: NonNullable<ForwardEquipmentKindAutocompleteProps<true>['value']>;
  onChange: NonNullable<ForwardEquipmentKindAutocompleteProps<true>['onChange']>;
  sx?: SxProps;
}) {
  const { t, i18n } = useAmbientTranslation();
  const lang = resolvedLanguage(i18n);

  return (
    <EquipmentKindAutocomplete
      value={value}
      onChange={handleChange}
      sx={sx}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={option.label?.[lang] ?? ''} size='small' />;
        })
      }
      textFieldProps={(params) => ({ ...params, label: t('field.equipment.kind') })}
      multiple
      capacity={null}
      configurationKindCode={null}
      vehicleIds={emptyVehicleIds}
    />
  );
}

export function ProjectManagerFilter({
  value,
  onChange: handleChange,
  sx,
}: {
  value: NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>;
  onChange: NonNullable<ForwardRepresentativeAutocompleteProps<true>['onChange']>;
  sx?: SxProps<Theme>;
}) {
  const { t } = useAmbientTranslation();

  return (
    <RepresentativeAutocomplete
      value={value}
      onChange={handleChange}
      sx={sx}
      textFieldProps={() => ({ label: t('field.client.projectManager', { ns: 'serviceCall' }) })}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' />;
        })
      }
      multiple
    />
  );
}

export function RepresentativeFilter({
  value,
  onChange: handleChange,
  sx,
}: {
  value: NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>;
  onChange: NonNullable<ForwardRepresentativeAutocompleteProps<true>['onChange']>;

  sx?: SxProps<Theme>;
}) {
  const { t } = useTranslation('jobs');

  return (
    <RepresentativeAutocomplete
      value={value}
      onChange={handleChange}
      sx={sx}
      textFieldProps={() => ({ label: t('dialog.filter.representative') })}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' />;
        })
      }
      multiple
    />
  );
}

export enum JobFiltersSearchParams {
  // Used by all job types
  SEARCH_TERM = 'searchTerm',
  ARRIVAL_DATE_START = 'arrivalDateStart',
  ARRIVAL_DATE_END = 'arrivalDateEnd',
  REPRESENTATIVE_ID = 'representativeId',
  STATUS = 'status',

  // Used by service call and quote
  CAPACITY = 'capacity',
  DISPATCH_BRANCH_ID = 'dispatchBranchId',
  EQUIPMENT_KIND_CODE = 'equipmentKindCode',
  KIND = 'kind',
  PROJECT_MANAGER_ID = 'projectManagerId',
}

export const searchTermSearchParam = createSearchParam<string>(
  (searchParams) => searchParams.get(JobFiltersSearchParams.SEARCH_TERM) || '',
  (searchTerm, searchParams) => {
    if (searchTerm) searchParams.set(JobFiltersSearchParams.SEARCH_TERM, searchTerm);
  },
);

/** The date format string used to parse & serialize the arrival date from/to the URL search params. */
export const ARRIVAL_DATE_FORMAT = 'yyyy-MM-dd';

export const arrivalDateSearchParam = createSearchParam<{ readonly start: DateTime<true>; readonly end: DateTime<true> } | null>(
  (searchParams) => {
    const deserialize = (name: string) => DateTime.fromFormat(searchParams.get(name) || '', ARRIVAL_DATE_FORMAT);
    const start = deserialize(JobFiltersSearchParams.ARRIVAL_DATE_START);
    const end = deserialize(JobFiltersSearchParams.ARRIVAL_DATE_END);
    // Only return an object if both dates are valid, since `arrivalDate` is a date range.
    return start.isValid && end.isValid ? { start, end } : null;
  },
  (arrivalDate, searchParams) => {
    if (!arrivalDate) return;
    searchParams.set(JobFiltersSearchParams.ARRIVAL_DATE_START, arrivalDate.start.toFormat(ARRIVAL_DATE_FORMAT));
    searchParams.set(JobFiltersSearchParams.ARRIVAL_DATE_END, arrivalDate.end.toFormat(ARRIVAL_DATE_FORMAT));
  },
  (value) => value && [value.start.toMillis(), value.end.toMillis()],
);

export const representativesSearchParam = createSearchParam<readonly { readonly id: DataID }[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.REPRESENTATIVE_ID).map((id) => ({ id })),
  (representatives, searchParams) => {
    for (const { id } of representatives) searchParams.append(JobFiltersSearchParams.REPRESENTATIVE_ID, id);
  },
  (representatives) => representatives.map(({ id }) => id),
);

export const capacitiesSearchParam = createSearchParam<readonly number[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.CAPACITY).map(Number).filter(Number.isInteger),
  (capacities, searchParams) => {
    for (const capacity of capacities) searchParams.append(JobFiltersSearchParams.CAPACITY, `${capacity}`);
  },
);

export const dispatchBranchesSearchParam = createSearchParam<readonly { readonly id: DataID }[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.DISPATCH_BRANCH_ID).map((id) => ({ id })),
  (dispatchBranches, searchParams) => {
    for (const { id } of dispatchBranches) searchParams.append(JobFiltersSearchParams.DISPATCH_BRANCH_ID, id);
  },
  (dispatchBranches) => dispatchBranches.map(({ id }) => id),
);

export const equipmentKindsSearchParam = createSearchParam<readonly { readonly code: number }[]>(
  (searchParams) =>
    searchParams
      .getAll(JobFiltersSearchParams.EQUIPMENT_KIND_CODE)
      .map(Number)
      .filter(Number.isInteger)
      .map((code) => ({ code })),
  (equipmentKinds, searchParams) => {
    for (const { code } of equipmentKinds) searchParams.append(JobFiltersSearchParams.EQUIPMENT_KIND_CODE, `${code}`);
  },
  (equipmentKinds) => equipmentKinds.map(({ code }) => code),
);

export const projectManagersSearchParam = createSearchParam<readonly { readonly id: DataID }[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.PROJECT_MANAGER_ID).map((id) => ({ id })),
  (projectManagers, searchParams) => {
    for (const { id } of projectManagers) searchParams.append(JobFiltersSearchParams.PROJECT_MANAGER_ID, id);
  },
  (projectManagers) => projectManagers.map(({ id }) => id),
);

/** Defines the set of search params available in all job filters. */
export const baseJobFilterParams = {
  searchTerm: searchTermSearchParam,
  arrivalDate: arrivalDateSearchParam,
  representatives: representativesSearchParam,
};

const throwRespGridFromSearchParams = () => {
  throw new TypeError('ResponsiveGridFilters cannot be created from search params');
};

const labelValue = (label: Record<string, string> | null | undefined): Value =>
  label ? Object.entries(label).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) : null;

export const dispatchBranchesResponsiveGridSearchParam = createSearchParam<
  NonNullable<ForwardDispatchBranchAutocompleteProps<true>['value']>
>(throwRespGridFromSearchParams, dispatchBranchesSearchParam.writeToSearchParams, (dispatchBranches) =>
  dispatchBranches.map(({ deletedAt, id, label }) => [deletedAt, id, labelValue(label)]),
);

export const equipmentKindsResponsiveGridSearchParam = createSearchParam<NonNullable<ForwardEquipmentKindAutocompleteProps<true>['value']>>(
  throwRespGridFromSearchParams,
  equipmentKindsSearchParam.writeToSearchParams,
  (equipmentKinds) => equipmentKinds.map(({ code, id, label }) => [code, id, labelValue(label)]),
);

export const projectManagersResponsiveGridSearchParam = createSearchParam<
  NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>
>(throwRespGridFromSearchParams, projectManagersSearchParam.writeToSearchParams, (projectManagers) =>
  projectManagers.map(({ deletedAt, id, label }) => [deletedAt, id, label ?? null]),
);

export const representativesResponsiveGridSearchParam = createSearchParam<
  NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>
>(throwRespGridFromSearchParams, representativesSearchParam.writeToSearchParams, (representatives) =>
  representatives.map(({ deletedAt, id, label }) => [deletedAt, id, label]),
);

/** Handles job filters initialization and modification according to the right business rules. */
export function useJobFilters<TFilters extends ISearchParams>(
  $key: JobFilters_useJobFiltersFragment$key,
  sharedStateKey: SharedStateKey<TFilters | null>,
  emptyFilters: TFilters,
  salesDefaultFilters: TFilters,
  deserializeFilters: (searchParams: URLSearchParams) => TFilters,
): [TFilters, Dispatch<SetStateAction<TFilters>>] {
  const [searchParams, setSearchParams] = useSearchParams();
  const [stateFilters, setStateFilters] = useSharedState(appSharedStateContext, sharedStateKey);

  const $data = useFragment(
    graphql`
      fragment JobFilters_useJobFiltersFragment on Employee {
        roles
      }
    `,
    $key,
  );

  const filters = useMemo(() => {
    if (stateFilters) return stateFilters;
    const searchParamsFilters = deserializeFilters(searchParams);
    if (searchParamsFilters !== emptyFilters) return searchParamsFilters;
    const hasSalesRole = $data.roles.includes('salesRepresentative') || $data.roles.includes('salesSupervisor');
    if (hasSalesRole) return salesDefaultFilters;
    return emptyFilters;
  }, [$data.roles, deserializeFilters, emptyFilters, salesDefaultFilters, searchParams, stateFilters]);

  // Whenever filters change, sync them back to both the shared state and url search params
  const syncFiltersToStateAndSearchParams = useEffectEvent(() => {
    if (stateFilters !== filters) {
      setStateFilters(filters);
    }
    if (searchParams.toString() !== filters.toSearchParams().toString()) {
      setSearchParams(filters.toSearchParams(), { replace: true });
    }
  });
  useEffect(syncFiltersToStateAndSearchParams, [filters, searchParams, syncFiltersToStateAndSearchParams]);

  return useMemo(() => [filters, setStateFilters as Dispatch<SetStateAction<TFilters>>], [filters, setStateFilters]);
}

/** Return a search params string (including the leading `?` if there is at least one search param) for a given job
 * filters shared state key. Can be used to build route urls. */
export function useJobFiltersSearchParams(sharedStateKey: SharedStateKey<ISearchParams | null>) {
  const [jobFilters] = useSharedState(appSharedStateContext, sharedStateKey);
  const searchParams = useMemo(() => jobFilters?.toSearchParams(), [jobFilters]);
  return useMemo(() => (searchParams?.size ? `?${searchParams.toString()}` : ''), [searchParams]);
}
