import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldSetter,
  useFieldValidation,
  useFieldValue,
} from '../../common/utils/forms';
import { DateTime } from 'luxon';
import { fetchQuery, useFragment, useLazyLoadQuery, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { SaleProjectFields_DepartureDateFragment$key } from './__generated__/SaleProjectFields_DepartureDateFragment.graphql';
import { SaleProjectFields_ReadyDateFragment$key } from './__generated__/SaleProjectFields_ReadyDateFragment.graphql';
import { SaleProjectFields_IsEstablishedScheduleFragment$key } from './__generated__/SaleProjectFields_IsEstablishedScheduleFragment.graphql';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { Box, Checkbox, CircularProgress, FormControlLabel, InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';
import { SaleProjectFields_WorkDescriptionFragment$key } from './__generated__/SaleProjectFields_WorkDescriptionFragment.graphql';
import { DispatchBranchAutocomplete, ForwardDispatchBranchAutocompleteProps } from '../../common/components/DispatchBranchAutocomplete';
import { SaleProjectFields_DispatchBranchFragment$key } from './__generated__/SaleProjectFields_DispatchBranchFragment.graphql';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { InactiveStartAdornment } from '../../common/InactiveStartAdornment';
import { ForwardSalesBranchAutocompleteProps, SalesBranchAutocomplete } from '../../common/components/SalesBranchAutocomplete';
import { SaleProjectFields_SalesBranchFragment$key } from './__generated__/SaleProjectFields_SalesBranchFragment.graphql';
import { SaleProjectFields_IsSecondServingFragment$key } from './__generated__/SaleProjectFields_IsSecondServingFragment.graphql';
import {
  dateFormat,
  dateTimeFormat,
  parseDateTime,
  parseTimeOnly,
  roundDateTimeToMinuteStep,
  timeFormat,
} from '../../common/utils/dateTimeUtils';
import { RoundedDateTimePicker } from '../../common/components/FormDateTimePicker';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers-pro';
import { SaleProjectFields_UseDispatchBranchChangeFragment$key } from './__generated__/SaleProjectFields_UseDispatchBranchChangeFragment.graphql';
import { ForwardNatureOfWorkAutocompleteProps, NatureOfWorkAutocomplete } from '../../common/components/NatureOfWorkAutocomplete';
import { SaleProjectFields_NatureOfWorkFragment$key } from './__generated__/SaleProjectFields_NatureOfWorkFragment.graphql';
import {
  castNatureOfWorkSubCategoryEnum,
  isNatureOfWorkSubCategoryEnum,
  NatureOfWorkSubCategoryEnum,
} from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { SaleProjectFields_NatureOfWorkSubCategoryFragment$key } from './__generated__/SaleProjectFields_NatureOfWorkSubCategoryFragment.graphql';
import { NatureOfWorkSubCategoryAutocomplete } from '../../common/components/NatureOfWorkSubCategoryAutocomplete';
import { castWorkScheduleEnum, isWorkScheduleEnum, WorkScheduleEnum } from '../../__enums__/WorkScheduleEnum';
import { SaleProjectFields_WorkScheduleFragment$key } from './__generated__/SaleProjectFields_WorkScheduleFragment.graphql';
import { saleFormContext } from '../SaleFields';
import { useSuggestions } from '../useSuggestions';
import { SaleProjectFields_NatureOfWorkInput_SuggestionsFragment$key } from './__generated__/SaleProjectFields_NatureOfWorkInput_SuggestionsFragment.graphql';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment$key } from './__generated__/SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment.graphql';
import { SuggestionPromptInput } from '../../common/components/__generated__/SuggestionsFakeQuery.graphql';
import {
  AutomaticSuggestionList,
  emptySuggestionPromptInput,
  scoreThreshold,
  suggestionCount,
  Suggestions,
} from '../../common/components/Suggestions';
import { SaleProjectFields_WorkDescription_SuggestionsQuery } from './__generated__/SaleProjectFields_WorkDescription_SuggestionsQuery.graphql';
import { SaleProjectFields_WorkDescriptionInput_SuggestionsFragment$key } from './__generated__/SaleProjectFields_WorkDescriptionInput_SuggestionsFragment.graphql';
import { SaleProjectFields_DispatchBranchInput_SuggestionsFragment$key } from './__generated__/SaleProjectFields_DispatchBranchInput_SuggestionsFragment.graphql';
import { SaleProjectFields_SaleBranchInput_SuggestionsFragment$key } from './__generated__/SaleProjectFields_SaleBranchInput_SuggestionsFragment.graphql';
import { SaleProjectFields_NatureOfWorkSubCategoriesFragment$key } from './__generated__/SaleProjectFields_NatureOfWorkSubCategoriesFragment.graphql';
import { SaleProjectFields_WorkSchedulesFragment$key } from './__generated__/SaleProjectFields_WorkSchedulesFragment.graphql';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { SaleProjectFields_useFieldSchedulePeriodFragment$key } from './__generated__/SaleProjectFields_useFieldSchedulePeriodFragment.graphql';
import { SaleProjectFields_useFieldScheduleStartTimeFragment$key } from './__generated__/SaleProjectFields_useFieldScheduleStartTimeFragment.graphql';
import { SaleProjectFields_useFieldScheduleEndTimeFragment$key } from './__generated__/SaleProjectFields_useFieldScheduleEndTimeFragment.graphql';
import { SaleProjectFields_IncludeWeekendsAndHolidaysFragment$key } from './__generated__/SaleProjectFields_IncludeWeekendsAndHolidaysFragment.graphql';
import { useFieldArrivalDateRead } from './ProjectBaseFields';
import { useOperations, useOperationsError } from '../../AppSharedState';
import { useCancellableSubscription } from '../../common/hooks/useCancellableSubscription';
import { useEffectEvent } from '../../common/utils/effectUtils';
import * as Sentry from '@sentry/react';
import { SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment$key } from './__generated__/SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment.graphql';
import { SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment$key } from './__generated__/SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment.graphql';
import {
  SaleProjectFields_useIncludeWeekendsAndHolidaysQuery,
  SaleProjectFields_useIncludeWeekendsAndHolidaysQuery$variables,
} from './__generated__/SaleProjectFields_useIncludeWeekendsAndHolidaysQuery.graphql';
import { _throw } from '../../common/utils/_throw';
import { JobStage } from '../jobStage';
import { RequiredForInputLabel, RequiredForStatus } from '../RequiredForJobStatus';
import { WorkScheduleAutocomplete } from '../../common/components/WorkScheduleAutocomplete';
import { SaleProjectFields_ReadyDateReadFragment$key } from './__generated__/SaleProjectFields_ReadyDateReadFragment.graphql';
import { useFlag } from '@unleash/proxy-client-react';

function ProjectCheckboxInput({
  value,
  setValue,
  labelKey,
  disabled,
}: {
  value: boolean;
  setValue: SetValueFn<boolean>;
  labelKey: string;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback((_: SyntheticEvent, v: boolean) => setValue(v), [setValue]);

  return (
    <FormControlLabel
      value={value}
      onChange={handleChange}
      checked={value}
      control={<Checkbox />}
      label={t(labelKey)}
      disabled={disabled}
    />
  );
}

const fieldDepartureDateKey = createFieldKey<DateTime | null>();

export function useFieldDepartureDate($key: SaleProjectFields_DepartureDateFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_DepartureDateFragment on ISale @argumentDefinitions(isCopy: { type: "Boolean!" }) {
        ... on ServiceCall {
          transferredAt @skip(if: $isCopy)
        }
        project {
          departureDate @skip(if: $isCopy)
        }
      }
    `,
    $key,
  );

  const isServiceCallTransferred = $data?.transferredAt != null;
  const [departureDate, setDepartureDate] = useField(saleFormContext, fieldDepartureDateKey, () =>
    parseDateTime($data?.project.departureDate),
  );

  const useValidationDepartureDate = useFieldValidation(saleFormContext, fieldDepartureDateKey);

  const today = useMemo(() => DateTime.now(), []);
  useValidationDepartureDate(
    (val, { isDirty }) => (isDirty && val != null && val.diff(today).as('milliseconds') < 0 ? 'error.dateInThePast' : true),
    [today],
    'change:past',
  );

  const useMapperDepartureDate = useFieldMapper(saleFormContext, fieldDepartureDateKey);
  useMapperDepartureDate((val) => ({ project: { departureDate: val?.toJSON() ?? null } }), [], 'save');

  const isDepartureDateDirty = useFieldIsDirty(saleFormContext, fieldDepartureDateKey);
  const editTimeEnabled = useFlag('app_enable_edit_time_when_transferred');

  const renderDepartureDate = useCallback(
    () =>
      isServiceCallTransferred && editTimeEnabled ? (
        <DepartureDateTime value={departureDate} setValue={setDepartureDate} disableDate disableTime={departureDate == null} />
      ) : (
        <DepartureDate value={departureDate} setValue={setDepartureDate} disabled={disabled} />
      ),
    [departureDate, disabled, editTimeEnabled, isServiceCallTransferred, setDepartureDate],
  );

  return {
    departureDate,
    setDepartureDate,
    renderDepartureDate,
    isDepartureDateDirty,
    useValidationDepartureDate,
  };
}

function DepartureDate({
  value,
  setValue,
  disabled,
}: {
  value: DateTime | null;
  setValue: SetValueFn<DateTime | null>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasError = useFieldHasErrors(saleFormContext, fieldDepartureDateKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldDepartureDateKey);

  const handleChange = useCallback((v: DateTime | null, _: PickerChangeHandlerContext<DateTimeValidationError>) => setValue(v), [setValue]);

  return (
    <RoundedDateTimePicker
      label={t('field.project.date.departure')}
      minutesStep={30}
      timeSteps={{ minutes: 30 }}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      ampm={false}
      format={dateTimeFormat}
      slotProps={{
        textField: {
          inputProps: {
            'data-label-key': 'field.project.date.departure',
          },
          error: hasError,
          helperText: message ? t(message, { ...errorParams }) : '',
        },
      }}
      disablePast
    />
  );
}

function DepartureDateTime({
  value,
  setValue,
  disableDate,
  disableTime,
}: {
  value: DateTime | null;
  setValue: SetValueFn<DateTime | null>;
  disableDate: boolean;
  disableTime: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasError = useFieldHasErrors(saleFormContext, fieldDepartureDateKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldDepartureDateKey);

  const handleChange = useCallback((v: DateTime | null) => setValue(v), [setValue]);

  return (
    <RoundedDateTimePicker
      split
      value={value}
      onChange={handleChange}
      dateInput={{
        fieldOnly: true,
        label: t('field.project.date.departure'),
        disabled: disableDate,
        disablePast: true,
        format: dateFormat,
        slotProps: {
          textField: {
            inputProps: {
              'data-label-key': 'field.project.date.departure',
            },
            error: hasError,
            helperText: message && t(message, { ...errorParams }),
          },
        },
      }}
      timeInput={{
        minutesStep: 30,
        timeSteps: { minutes: 30 },
        ampm: false,
        format: timeFormat,
        disabled: disableTime,
        disablePast: value?.hasSame(DateTime.now(), 'day') ?? false, // Disable past only if the date is today,
        slotProps: {
          textField: {
            error: hasError,
          },
        },
      }}
    />
  );
}

const fieldReadyDateKey = createFieldKey<DateTime | null>();

export function useFieldReadyDateRead($key: SaleProjectFields_ReadyDateReadFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_ReadyDateReadFragment on ISaleProject @argumentDefinitions(isCopy: { type: "Boolean!" }) {
        readyDate @skip(if: $isCopy)
      }
    `,
    $key,
  );
  const readyDate = useFieldValue(saleFormContext, fieldReadyDateKey, () => parseDateTime($data?.readyDate));

  const useMapper = useFieldMapper(saleFormContext, fieldReadyDateKey);
  useMapper((val) => ({ project: { readyDate: val?.toJSON() ?? null } }), [], 'save');

  return { readyDate };
}
export function useFieldReadyDate($key: SaleProjectFields_ReadyDateFragment$key | null | undefined, disabled: boolean, required: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_ReadyDateFragment on ISale @argumentDefinitions(isCopy: { type: "Boolean!" }) {
        ... on ServiceCall {
          transferredAt @skip(if: $isCopy)
        }
        project {
          ...SaleProjectFields_ReadyDateReadFragment @arguments(isCopy: $isCopy)
        }
      }
    `,
    $key,
  );
  const isServiceCallTransferred = $data?.transferredAt != null;
  const { readyDate, ...rest } = useFieldReadyDateRead($data?.project);
  const setReadyDate = useFieldSetter(saleFormContext, fieldReadyDateKey);

  const useValidationReadyDate = useFieldValidation(saleFormContext, fieldReadyDateKey);
  useValidationReadyDate((val) => (required ? !!val : true), [required], 'transferable:required');

  const today = useMemo(() => DateTime.now(), []);
  useValidationReadyDate(
    (val, { isDirty }) => (isDirty && val != null && val.diff(today).as('milliseconds') < 0 ? 'error.dateInThePast' : true),
    [today],
    'change:past',
  );

  const editTimeEnabled = useFlag('app_enable_edit_time_when_transferred');

  const renderReadyDate = useCallback(
    () =>
      isServiceCallTransferred && editTimeEnabled ? (
        <ReadyDateTime value={readyDate} setValue={setReadyDate} disableDate disableTime={readyDate == null} required={required} />
      ) : (
        <ReadyDate value={readyDate} setValue={setReadyDate} disabled={disabled} required={required} />
      ),
    [isServiceCallTransferred, editTimeEnabled, readyDate, setReadyDate, required, disabled],
  );

  return { readyDate, setReadyDate, renderReadyDate, useValidationReadyDate, ...rest };
}

function ReadyDate({
  value,
  setValue,
  disabled,
  required,
}: {
  value: DateTime | null;
  setValue: SetValueFn<DateTime | null>;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldReadyDateKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldReadyDateKey);

  const handleChange = useCallback((v: DateTime | null, _: PickerChangeHandlerContext<DateTimeValidationError>) => setValue(v), [setValue]);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = required
    ? {
        serviceCall: ['reserve', 'transfer'],
      }
    : {};

  return (
    <RoundedDateTimePicker
      label={<RequiredForInputLabel label={t('field.project.date.ready')} requiredFor={requiredFor} disabled={disabled} />}
      minutesStep={30}
      timeSteps={{ minutes: 30 }}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      ampm={false}
      format={dateTimeFormat}
      slotProps={{
        textField: {
          inputProps: {
            'data-label-key': 'field.project.date.ready',
          },
          error: hasErrors,
          helperText: message && t(message, { ...errorParams }),
        },
      }}
      disablePast
    />
  );
}

function ReadyDateTime({
  value,
  setValue,
  disableDate,
  disableTime,
  required,
}: {
  value: DateTime | null;
  setValue: SetValueFn<DateTime | null>;
  disableDate: boolean;
  disableTime: boolean;
  required: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldReadyDateKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldReadyDateKey);

  const handleChange = useCallback((v: DateTime | null) => setValue(v), [setValue]);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = required
    ? {
        serviceCall: ['reserve', 'transfer'],
      }
    : {};

  return (
    <RoundedDateTimePicker
      split
      value={value}
      onChange={handleChange}
      dateInput={{
        fieldOnly: true,
        label: <RequiredForInputLabel label={t('field.project.date.ready')} requiredFor={requiredFor} disabled={disableDate} />,
        disabled: disableDate,
        disablePast: true,
        format: dateFormat,
        slotProps: {
          textField: {
            inputProps: {
              'data-label-key': 'field.project.date.ready',
            },
            error: hasErrors,
            helperText: message && t(message, { ...errorParams }),
          },
        },
      }}
      timeInput={{
        minutesStep: 30,
        timeSteps: { minutes: 30 },
        ampm: false,
        format: timeFormat,
        disabled: disableTime,
        disablePast: value?.hasSame(DateTime.now(), 'day') ?? false, // Disable past only if the date is today,
        slotProps: {
          textField: {
            error: hasErrors,
          },
        },
      }}
    />
  );
}

const fieldIsEstablishedScheduleKey = createFieldKey<boolean>();

export function useFieldIsEstablishedScheduleRead($key: SaleProjectFields_IsEstablishedScheduleFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_IsEstablishedScheduleFragment on ISaleProject {
        isEstablishedSchedule
      }
    `,
    $key,
  );
  const isEstablishedSchedule = useFieldValue(saleFormContext, fieldIsEstablishedScheduleKey, () => $data?.isEstablishedSchedule ?? false);

  const useMapper = useFieldMapper(saleFormContext, fieldIsEstablishedScheduleKey);
  useMapper((v) => ({ project: { isEstablishedSchedule: v } }), [], 'save');

  return { isEstablishedSchedule };
}
export function useFieldIsEstablishedSchedule(
  $key: SaleProjectFields_IsEstablishedScheduleFragment$key | null | undefined,
  disabled: boolean,
) {
  const { isEstablishedSchedule, ...rest } = useFieldIsEstablishedScheduleRead($key);

  const setIsEstablishedSchedule = useFieldSetter(saleFormContext, fieldIsEstablishedScheduleKey);

  const renderIsEstablishedSchedule = useCallback(
    () => (
      <ProjectCheckboxInput
        value={isEstablishedSchedule}
        setValue={setIsEstablishedSchedule}
        labelKey='field.project.work.isEstablishedSchedule'
        disabled={disabled}
      />
    ),
    [disabled, isEstablishedSchedule, setIsEstablishedSchedule],
  );

  return { isEstablishedSchedule, setIsEstablishedSchedule, renderIsEstablishedSchedule, ...rest };
}

export function useFieldSchedulePeriod($key: SaleProjectFields_useFieldSchedulePeriodFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_useFieldSchedulePeriodFragment on ISaleProject {
        ...SaleProjectFields_IsEstablishedScheduleFragment
        schedulePeriod {
          ...SaleProjectFields_useFieldScheduleStartTimeFragment
          ...SaleProjectFields_useFieldScheduleEndTimeFragment
        }
      }
    `,
    $key,
  );

  const { renderStartTime, useValidationScheduleStartTime, ...restStartTime } = useFieldScheduleStartTime($data?.schedulePeriod, disabled);
  const { renderEndTime, useValidationScheduleEndTime, ...restEndTime } = useFieldScheduleEndTime($data?.schedulePeriod, disabled);
  const { isEstablishedSchedule } = useFieldIsEstablishedScheduleRead($data);
  useValidationScheduleStartTime(
    (val) => (isEstablishedSchedule ? !!val : true),
    [isEstablishedSchedule],
    'transferable,submittable:required',
  );
  useValidationScheduleEndTime(
    (val) => (isEstablishedSchedule ? !!val : true),
    [isEstablishedSchedule],
    'transferable,submittable:required',
  );

  const renderSchedulePeriod = useCallback(() => {
    return isEstablishedSchedule ? (
      <>
        <Box sx={(theme) => ({ gridColumn: '3', [theme.breakpoints.down('xl')]: { gridColumn: '1' } })}>{renderStartTime()}</Box>
        <Box sx={(theme) => ({ gridColumn: '4', [theme.breakpoints.down('xl')]: { gridColumn: '2' } })}>{renderEndTime()}</Box>
      </>
    ) : null;
  }, [isEstablishedSchedule, renderEndTime, renderStartTime]);

  return { renderSchedulePeriod, ...restStartTime, ...restEndTime };
}

const fieldScheduleStartTimeKey = createFieldKey<DateTime<boolean> | null>();

export function useFieldScheduleStartTime(
  $key: SaleProjectFields_useFieldScheduleStartTimeFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_useFieldScheduleStartTimeFragment on TimeRange {
        startTime
      }
    `,
    $key,
  );

  const [startTime, setStartTime] = useField(saleFormContext, fieldScheduleStartTimeKey, () => parseTimeOnly($data?.startTime));
  const useMapper = useFieldMapper(saleFormContext, fieldScheduleStartTimeKey);
  useMapper((v) => ({ project: { schedulePeriod: { startTime: v ? `PT${v.hour}H${v.minute}M` : null } } }), [], 'save');

  const useValidationScheduleStartTime = useFieldValidation(saleFormContext, fieldScheduleStartTimeKey);
  const hasErrors = useFieldHasErrors(saleFormContext, fieldScheduleStartTimeKey);

  const renderStartTime = useCallback(
    () => (
      <TimeInput
        value={startTime}
        setValue={setStartTime}
        labelKey='field.project.schedulePeriod.startTime'
        disabled={disabled}
        hasErrors={hasErrors}
      />
    ),
    [startTime, setStartTime, disabled, hasErrors],
  );

  return { startTime, setStartTime, renderStartTime, useValidationScheduleStartTime };
}

const fieldScheduleEndTimeKey = createFieldKey<DateTime<boolean> | null>();

export function useFieldScheduleEndTime($key: SaleProjectFields_useFieldScheduleEndTimeFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_useFieldScheduleEndTimeFragment on TimeRange {
        endTime
      }
    `,
    $key,
  );

  const [endTime, setEndTime] = useField(saleFormContext, fieldScheduleEndTimeKey, () => parseTimeOnly($data?.endTime));
  const useMapper = useFieldMapper(saleFormContext, fieldScheduleEndTimeKey);
  useMapper((v) => ({ project: { schedulePeriod: { endTime: v ? `PT${v.hour}H${v.minute}M` : null } } }), [], 'save');

  const useValidationScheduleEndTime = useFieldValidation(saleFormContext, fieldScheduleEndTimeKey);
  const hasErrors = useFieldHasErrors(saleFormContext, fieldScheduleEndTimeKey);

  const renderEndTime = useCallback(
    () => (
      <TimeInput
        value={endTime}
        setValue={setEndTime}
        labelKey='field.project.schedulePeriod.endTime'
        disabled={disabled}
        hasErrors={hasErrors}
      />
    ),
    [endTime, setEndTime, disabled, hasErrors],
  );

  return { endTime, setEndTime, renderEndTime, useValidationScheduleEndTime };
}

function TimeInput({
  value,
  setValue,
  labelKey,
  disabled,
  hasErrors,
}: {
  value: DateTime<boolean> | null;
  setValue: SetValueFn<DateTime<boolean> | null>;
  labelKey: string;
  disabled: boolean;
  hasErrors: boolean;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback((v: DateTime<boolean> | null) => setValue(v), [setValue]);

  const handleRound = useCallback(() => {
    if (!value) {
      return;
    }

    const rounded = roundDateTimeToMinuteStep(value, 30);
    if (!rounded.equals(value)) {
      handleChange?.(rounded);
    }
  }, [value, handleChange]);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <TimePicker
      value={value}
      onChange={handleChange}
      minutesStep={30}
      timeSteps={{ minutes: 30 }}
      label={<RequiredForInputLabel label={t(labelKey, { ns: 'jobs' })} requiredFor={requiredFor} disabled={disabled} />}
      disabled={disabled}
      timezone='default'
      views={['hours', 'minutes']}
      slotProps={{
        textField: {
          onBlur: handleRound,
          error: hasErrors,
        },
      }}
    />
  );
}

export type FieldNatureOfWork = ForwardNatureOfWorkAutocompleteProps['value'];
const fieldNatureOfWorkKey = createFieldKey<FieldNatureOfWork>();

export function useFieldNatureOfWorkRead($key: SaleProjectFields_NatureOfWorkFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_NatureOfWorkFragment on ISaleProject {
        natureOfWork {
          id
          code
          label
        }
      }
    `,
    $key,
  );
  const natureOfWork = useFieldValue(saleFormContext, fieldNatureOfWorkKey, () => $data?.natureOfWork ?? null);
  const isNatureOfWorkDirty = useFieldIsDirty(saleFormContext, fieldNatureOfWorkKey);

  const useMapper = useFieldMapper(saleFormContext, fieldNatureOfWorkKey);
  useMapper((v) => ({ project: { natureOfWorkCode: v?.code ?? null } }), [], 'save');
  useMapper(
    (v) => ({ salesRatesInput: { natureOfWorkCode: v?.code ?? _throw('Nature of work is required to recalculate') } }),
    [],
    'recalculate',
  );

  return { natureOfWork, isNatureOfWorkDirty };
}
export function useFieldNatureOfWork($key: SaleProjectFields_NatureOfWorkFragment$key | null | undefined, disabled: boolean) {
  const { natureOfWork, ...rest } = useFieldNatureOfWorkRead($key);
  const setNatureOfWork = useFieldSetter(saleFormContext, fieldNatureOfWorkKey);

  const useValidation = useFieldValidation(saleFormContext, fieldNatureOfWorkKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const renderNatureOfWork = useCallback(
    (suggestions$key: SaleProjectFields_NatureOfWorkInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => {
      return (
        <NatureOfWorkInput $key={suggestions$key} saleKind={saleKind} value={natureOfWork} setValue={setNatureOfWork} disabled={disabled} />
      );
    },
    [disabled, natureOfWork, setNatureOfWork],
  );

  return { natureOfWork, setNatureOfWork, renderNatureOfWork, ...rest };
}

function NatureOfWorkInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: SaleProjectFields_NatureOfWorkInput_SuggestionsFragment$key | null | undefined;
  value: FieldNatureOfWork;
  setValue: SetValueFn<FieldNatureOfWork>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldNatureOfWorkKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldNatureOfWorkKey);

  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_NatureOfWorkInput_SuggestionsFragment on ISale
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback<NonNullable<ForwardNatureOfWorkAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <NatureOfWorkAutocomplete
      value={value}
      onChange={handleChange}
      suggestionPromptInput={suggestionPromptInput}
      suggestible
      disabled={disabled}
      textFieldProps={() => ({
        label: <RequiredForInputLabel label={t('field.project.work.natureOfWork')} requiredFor={requiredFor} disabled={disabled} />,
        'data-label-key': 'field.project.work.natureOfWork',
        error: hasErrors,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
      })}
    />
  );
}

export type FieldNatureOfWorkSubCategory = NatureOfWorkSubCategoryEnum | null;
const fieldNatureOfWorkSubCategoryKey = createFieldKey<FieldNatureOfWorkSubCategory>();

export function useFieldNatureOfWorkSubCategoryRead($key: SaleProjectFields_NatureOfWorkSubCategoryFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_NatureOfWorkSubCategoryFragment on ISaleProject {
        natureOfWorkSubCategory
      }
    `,
    $key,
  );
  const natureOfWorkSubCategory = useFieldValue(saleFormContext, fieldNatureOfWorkSubCategoryKey, () =>
    $data?.natureOfWorkSubCategory ? castNatureOfWorkSubCategoryEnum($data.natureOfWorkSubCategory) : null,
  );
  const natureOfWorkSubCategoryIsDirty = useFieldIsDirty(saleFormContext, fieldNatureOfWorkSubCategoryKey);

  const useMapper = useFieldMapper(saleFormContext, fieldNatureOfWorkSubCategoryKey);
  useMapper((v) => ({ project: { natureOfWorkSubCategory: v } }), [], 'save');

  return { natureOfWorkSubCategory, natureOfWorkSubCategoryIsDirty };
}
export function useFieldNatureOfWorkSubCategory(
  $key: SaleProjectFields_NatureOfWorkSubCategoryFragment$key | null | undefined,
  categories$key: SaleProjectFields_NatureOfWorkSubCategoriesFragment$key | null | undefined,
  disabled: boolean,
) {
  const { natureOfWorkSubCategory, ...rest } = useFieldNatureOfWorkSubCategoryRead($key);
  const setNatureOfWorkSubCategory = useFieldSetter(saleFormContext, fieldNatureOfWorkSubCategoryKey);

  const categories$data = useFragment(
    graphql`
      fragment SaleProjectFields_NatureOfWorkSubCategoriesFragment on NatureOfWorkLookup {
        natureOfWorkSubCategories
      }
    `,
    categories$key,
  );

  const useValidation = useFieldValidation(saleFormContext, fieldNatureOfWorkSubCategoryKey);
  useValidation((v) => !!v, [], 'submittable,transferable:required');

  const options = useMemo(
    () => categories$data?.natureOfWorkSubCategories?.filter(isNatureOfWorkSubCategoryEnum) ?? [],
    [categories$data?.natureOfWorkSubCategories],
  );

  const renderNatureOfWorkSubCategory = useCallback(
    (input$key: SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => {
      return (
        <NatureOfWorkSubCategoryInput
          $key={input$key}
          value={natureOfWorkSubCategory}
          setValue={setNatureOfWorkSubCategory}
          saleKind={saleKind}
          options={options}
          disabled={disabled}
        />
      );
    },
    [natureOfWorkSubCategory, setNatureOfWorkSubCategory, options, disabled],
  );

  return { natureOfWorkSubCategory, setNatureOfWorkSubCategory, renderNatureOfWorkSubCategory, ...rest };
}

function NatureOfWorkSubCategoryInput({
  $key,
  value,
  setValue,
  saleKind,
  options,
  disabled,
}: {
  $key: SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment$key | null | undefined;
  value: FieldNatureOfWorkSubCategory;
  setValue: SetValueFn<FieldNatureOfWorkSubCategory>;
  saleKind: ServiceCallKind;
  options: NatureOfWorkSubCategoryEnum[];
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldNatureOfWorkSubCategoryKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldNatureOfWorkSubCategoryKey);

  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment on ISale
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback(
    (v: FieldNatureOfWorkSubCategory) => {
      setValue(v);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  const sortedCategories = useMemo(
    () =>
      options
        .map((c) => ({ value: c, label: t(`natureOfWorkSubCategories.${c}`, { ns: 'common' }) }))
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((item) => item.value),
    [options, t],
  );

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <NatureOfWorkSubCategoryAutocomplete
      disabled={disabled}
      value={value}
      onChange={handleChange}
      options={sortedCategories}
      suggestionPromptInput={suggestionPromptInput}
      suggestible
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`natureOfWorkSubCategories.${o}`, { ns: 'common' })}
      textFieldProps={() => ({
        label: (
          <RequiredForInputLabel label={t('field.project.work.natureOfWorkSubCategory')} requiredFor={requiredFor} disabled={disabled} />
        ),
        'data-label-key': 'field.project.work.natureOfWorkSubCategory',
        error: hasErrors && !disabled,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
        disabled,
      })}
    />
  );
}

const fieldWorkScheduleKey = createFieldKey<WorkScheduleEnum | null>();

export function useFieldWorkSchedule(
  $key: SaleProjectFields_WorkScheduleFragment$key | null | undefined,
  schedules$key: SaleProjectFields_WorkSchedulesFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_WorkScheduleFragment on ISaleProject {
        workSchedule
      }
    `,
    $key,
  );

  const schedule$data = useFragment(
    graphql`
      fragment SaleProjectFields_WorkSchedulesFragment on NatureOfWorkLookup {
        workSchedules
      }
    `,
    schedules$key,
  );

  const [workSchedule, setWorkSchedule] = useField(saleFormContext, fieldWorkScheduleKey, () =>
    $data?.workSchedule ? castWorkScheduleEnum($data.workSchedule) : null,
  );

  const useValidation = useFieldValidation(saleFormContext, fieldWorkScheduleKey);
  useValidation((v) => !!v, [], 'transferable:required');

  const useMapper = useFieldMapper(saleFormContext, fieldWorkScheduleKey);
  useMapper((v) => ({ project: { workSchedule: v } }), [], 'save');

  const options = useMemo(() => schedule$data?.workSchedules?.filter(isWorkScheduleEnum) ?? [], [schedule$data?.workSchedules]);

  const renderWorkSchedule = useCallback(() => {
    return <WorkScheduleInput value={workSchedule} setValue={setWorkSchedule} options={options} disabled={disabled} />;
  }, [workSchedule, setWorkSchedule, options, disabled]);

  return { workSchedule, setWorkSchedule, renderWorkSchedule };
}

function WorkScheduleInput({
  value,
  setValue,
  options,
  disabled,
}: {
  value: WorkScheduleEnum | null;
  setValue: SetValueFn<WorkScheduleEnum | null>;
  options: WorkScheduleEnum[];
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldWorkScheduleKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldWorkScheduleKey);

  const handleChange = useCallback(
    (_: SyntheticEvent, v: WorkScheduleEnum | null) => {
      setValue(v);
    },
    [setValue],
  );

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    serviceCall: ['transfer'],
  };

  return (
    <WorkScheduleAutocomplete
      value={value}
      disabled={disabled}
      options={options}
      onChange={handleChange}
      textFieldProps={() => ({
        label: <RequiredForInputLabel label={t('field.project.work.workSchedule')} requiredFor={requiredFor} disabled={disabled} />,
        error: hasErrors && !disabled,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
      })}
    />
  );
}

const fieldWorkDescriptionKey = createFieldKey<string>();

export function useFieldWorkDescriptionRead($key: SaleProjectFields_WorkDescriptionFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_WorkDescriptionFragment on ISaleProject {
        workDescription
      }
    `,
    $key,
  );
  const workDescription = useFieldValue(saleFormContext, fieldWorkDescriptionKey, () => $data?.workDescription ?? '');

  const useMapper = useFieldMapper(saleFormContext, fieldWorkDescriptionKey);
  useMapper((v) => ({ project: { workDescription: v } }), [], 'save');

  return { workDescription };
}
export function useFieldWorkDescription($key: SaleProjectFields_WorkDescriptionFragment$key | null | undefined, disabled: boolean) {
  const { workDescription, ...rest } = useFieldWorkDescriptionRead($key);
  const setWorkDescription = useFieldSetter(saleFormContext, fieldWorkDescriptionKey);

  const useValidation = useFieldValidation(saleFormContext, fieldWorkDescriptionKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const renderWorkDescription = useCallback(
    (input$key: SaleProjectFields_WorkDescriptionInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => {
      return (
        <WorkDescriptionInput
          $key={input$key}
          value={workDescription}
          setValue={setWorkDescription}
          saleKind={saleKind}
          disabled={disabled}
        />
      );
    },
    [disabled, setWorkDescription, workDescription],
  );

  return { workDescription, setWorkDescription, renderWorkDescription, ...rest };
}

function WorkDescriptionInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: SaleProjectFields_WorkDescriptionInput_SuggestionsFragment$key | null | undefined;
  value: string;
  setValue: SetValueFn<string>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldWorkDescriptionKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldWorkDescriptionKey);

  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_WorkDescriptionInput_SuggestionsFragment on ISale
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  // HACK: Workaround a crash in ResizeObserver in MUI code: https://github.com/mui/base-ui/issues/167
  const workaroundTextFieldResizeObserverCrash: TextFieldProps = {
    // Need to use a fix row count to prevent height recalculation due to content changes.
    rows: 5,
    // Need to force visibility of vertical scrollbar to prevent height recalculation when it becomes visible otherwise.
    sx: { '& textarea': { overflowY: 'scroll !important' } },
  };

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <TextField
      error={hasErrors}
      multiline
      {...workaroundTextFieldResizeObserverCrash}
      label={<RequiredForInputLabel label={t('field.project.work.workDescription')} requiredFor={requiredFor} disabled={disabled} />}
      data-label-key='field.project.work.workDescription'
      value={value}
      onChange={handleChange}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Suggestions
              disabled={disabled}
              content={(close) => (
                <WorkDescriptionSuggestions
                  suggestionPromptInput={suggestionPromptInput}
                  onChange={(v) => {
                    setValue(v);
                    close();
                  }}
                />
              )}
            />
          </InputAdornment>
        ),
      }}
      helperText={message ? t(message, { ns: 'common', ...errorParams }) : ''}
    />
  );
}

function WorkDescriptionSuggestions({
  onChange: handleChange,
  suggestionPromptInput,
}: {
  onChange: (selectedValue: string) => void;
  suggestionPromptInput: SuggestionPromptInput | null;
}) {
  const results = useLazyLoadQuery<SaleProjectFields_WorkDescription_SuggestionsQuery>(
    graphql`
      query SaleProjectFields_WorkDescription_SuggestionsQuery(
        $scoreThreshold: Float!
        $suggestionPrompt: SuggestionPromptInput!
        $suggestionCount: Int!
      ) {
        suggestedWorkDescriptions(scoreThreshold: $scoreThreshold, prompt: $suggestionPrompt, count: $suggestionCount) {
          value
          score
        }
      }
    `,
    {
      scoreThreshold: scoreThreshold,
      suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
      suggestionCount: suggestionCount,
    },
  );

  const data = results.suggestedWorkDescriptions ?? [];

  return (
    <AutomaticSuggestionList keySelector={(v) => `${v}`} items={data} onChange={(selectedValue: string) => handleChange(selectedValue)} />
  );
}

type FieldDispatchBranch = ForwardDispatchBranchAutocompleteProps['value'];
const fieldDispatchBranchKey = createFieldKey<FieldDispatchBranch>();

export function useFieldDispatchBranchRead($key: SaleProjectFields_DispatchBranchFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_DispatchBranchFragment on ISaleProject {
        dispatchBranch {
          id
          label
          deletedAt
        }
      }
    `,
    $key,
  );
  const dispatchBranch = useFieldValue(saleFormContext, fieldDispatchBranchKey, () => $data?.dispatchBranch ?? null);
  const isDispatchBranchDirty = useFieldIsDirty(saleFormContext, fieldDispatchBranchKey);

  const useMapper = useFieldMapper(saleFormContext, fieldDispatchBranchKey);
  useMapper((v) => ({ project: { dispatchBranchId: v?.id ?? null } }), [], 'save');
  useMapper(
    (v) => ({ salesRatesInput: { dispatchBranchId: v?.id ?? _throw('Dispatch branch is required to recalculate') } }),
    [],
    'recalculate',
  );

  return { dispatchBranch, isDispatchBranchDirty };
}
export function useFieldDispatchBranch($key: SaleProjectFields_DispatchBranchFragment$key | null | undefined, disabled: boolean) {
  const { dispatchBranch, ...rest } = useFieldDispatchBranchRead($key);
  const setDispatchBranch = useFieldSetter(saleFormContext, fieldDispatchBranchKey);

  const useValidation = useFieldValidation(saleFormContext, fieldDispatchBranchKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const renderDispatchBranch = useCallback(
    (input$key: SaleProjectFields_DispatchBranchInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => {
      return (
        <DispatchBranchInput $key={input$key} value={dispatchBranch} setValue={setDispatchBranch} saleKind={saleKind} disabled={disabled} />
      );
    },
    [disabled, dispatchBranch, setDispatchBranch],
  );

  return { dispatchBranch, setDispatchBranch, renderDispatchBranch, ...rest };
}

function DispatchBranchInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: SaleProjectFields_DispatchBranchInput_SuggestionsFragment$key | null | undefined;
  value: FieldDispatchBranch;
  setValue: SetValueFn<FieldDispatchBranch>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldDispatchBranchKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldDispatchBranchKey);

  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_DispatchBranchInput_SuggestionsFragment on ISale
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback<NonNullable<ForwardDispatchBranchAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <DispatchBranchAutocomplete
      value={value}
      onChange={handleChange}
      suggestible
      suggestionPromptInput={suggestionPromptInput}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: <RequiredForInputLabel label={t('field.project.branch.dispatchBranch')} requiredFor={requiredFor} disabled={disabled} />,
        InputProps: {
          ...params.InputProps,
          startAdornment: (
            <>
              {value?.deletedAt && <InactiveStartAdornment />}
              {params.InputProps.startAdornment}
            </>
          ),
        },
        error: hasErrors,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
      })}
    />
  );
}

type FieldSalesBranch = ForwardSalesBranchAutocompleteProps['value'];
const fieldSalesBranchKey = createFieldKey<FieldSalesBranch>();

export function useFieldSalesBranchRead($key: SaleProjectFields_SalesBranchFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_SalesBranchFragment on ISaleProject {
        salesBranch {
          id
          label
          deletedAt
        }
      }
    `,
    $key,
  );
  const salesBranch = useFieldValue(saleFormContext, fieldSalesBranchKey, () => $data?.salesBranch ?? null);
  const isSalesBranchDirty = useFieldIsDirty(saleFormContext, fieldSalesBranchKey);

  const useMapper = useFieldMapper(saleFormContext, fieldSalesBranchKey);
  useMapper((v) => ({ project: { salesBranchId: v?.id ?? null } }), [], 'save');

  return { salesBranch, isSalesBranchDirty };
}
export function useFieldSalesBranch($key: SaleProjectFields_SalesBranchFragment$key | null | undefined, disabled: boolean) {
  const { salesBranch, ...rest } = useFieldSalesBranchRead($key);
  const setSalesBranch = useFieldSetter(saleFormContext, fieldSalesBranchKey);

  const useValidation = useFieldValidation(saleFormContext, fieldSalesBranchKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const renderSalesBranch = useCallback(
    (input$key: SaleProjectFields_SaleBranchInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => {
      return <SalesBranchInput $key={input$key} value={salesBranch} setValue={setSalesBranch} saleKind={saleKind} disabled={disabled} />;
    },
    [disabled, salesBranch, setSalesBranch],
  );

  return { salesBranch, setSalesBranch, renderSalesBranch, ...rest };
}

function SalesBranchInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: SaleProjectFields_SaleBranchInput_SuggestionsFragment$key | null | undefined;
  value: FieldSalesBranch;
  setValue: SetValueFn<FieldSalesBranch>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldSalesBranchKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldSalesBranchKey);

  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_SaleBranchInput_SuggestionsFragment on ISale @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback<NonNullable<ForwardSalesBranchAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <SalesBranchAutocomplete
      value={value}
      onChange={handleChange}
      suggestible
      suggestionPromptInput={suggestionPromptInput}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: <RequiredForInputLabel label={t('field.project.branch.salesBranch')} requiredFor={requiredFor} disabled={disabled} />,
        InputProps: {
          ...params.InputProps,
          startAdornment: (
            <>
              {value?.deletedAt && <InactiveStartAdornment />}
              {params.InputProps.startAdornment}
            </>
          ),
        },
        error: hasErrors,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
      })}
    />
  );
}

export function useDispatchBranchChange($key: SaleProjectFields_UseDispatchBranchChangeFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_UseDispatchBranchChangeFragment on ISaleProject {
        ...SaleProjectFields_DispatchBranchFragment
        ...SaleProjectFields_SalesBranchFragment
      }
    `,
    $key,
  );

  const { dispatchBranch, isDispatchBranchDirty } = useFieldDispatchBranchRead($data);
  const { setSalesBranch } = useFieldSalesBranch($data, disabled);

  useEffect(() => {
    if (isDispatchBranchDirty && dispatchBranch) {
      setSalesBranch(dispatchBranch);
    }
  }, [dispatchBranch, isDispatchBranchDirty, setSalesBranch]);
}

const fieldIsSecondServingKey = createFieldKey<boolean>();

export function useFieldIsSecondServingRead($key: SaleProjectFields_IsSecondServingFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_IsSecondServingFragment on ISaleProject {
        isSecondServing
      }
    `,
    $key,
  );
  const isSecondServing = useFieldValue(saleFormContext, fieldIsSecondServingKey, () => $data?.isSecondServing ?? false);
  const isSecondServingDirty = useFieldIsDirty(saleFormContext, fieldIsSecondServingKey);

  const useMapper = useFieldMapper(saleFormContext, fieldIsSecondServingKey);
  useMapper((v) => ({ project: { isSecondServing: v } }), [], 'save');

  return { isSecondServing, isSecondServingDirty };
}

export function useFieldIsSecondServing($key: SaleProjectFields_IsSecondServingFragment$key | null | undefined, disabled: boolean) {
  const { isSecondServing, ...rest } = useFieldIsSecondServingRead($key);
  const setIsSecondServing = useFieldSetter(saleFormContext, fieldIsSecondServingKey);

  const renderIsSecondServing = useCallback(
    () => (
      <ProjectCheckboxInput
        value={isSecondServing}
        setValue={setIsSecondServing}
        labelKey='field.project.date.isSecondServing'
        disabled={disabled}
      />
    ),
    [disabled, isSecondServing, setIsSecondServing],
  );

  return { isSecondServing, setIsSecondServing, renderIsSecondServing, ...rest };
}

const fieldIncludeWeekendsAndHolidays = createFieldKey<boolean>();
export function useFieldIncludeWeekendsAndHolidays(
  $key: SaleProjectFields_IncludeWeekendsAndHolidaysFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_IncludeWeekendsAndHolidaysFragment on ISaleProject {
        includeWeekendsAndHolidays
      }
    `,
    $key,
  );

  const [includeWeekendsAndHolidays, setIncludeWeekendsAndHolidays] = useField(
    saleFormContext,
    fieldIncludeWeekendsAndHolidays,
    () => $data?.includeWeekendsAndHolidays ?? false,
  );
  const useMapper = useFieldMapper(saleFormContext, fieldIncludeWeekendsAndHolidays);
  useMapper((v) => ({ project: { includeWeekendsAndHolidays: v } }), [], 'save');

  const renderIncludeWeekendsAndHolidays = useCallback(
    (loading?: boolean) =>
      !loading ? (
        <ProjectCheckboxInput
          value={includeWeekendsAndHolidays}
          setValue={setIncludeWeekendsAndHolidays}
          labelKey='field.project.date.includeWeekendsAndHolidays'
          disabled={disabled}
        />
      ) : (
        <LoadingCheckboxInput labelKey='field.project.date.includeWeekendsAndHolidays' />
      ),
    [disabled, includeWeekendsAndHolidays, setIncludeWeekendsAndHolidays],
  );

  return {
    includeWeekendsAndHolidays,
    setIncludeWeekendsAndHolidays,
    renderIncludeWeekendsAndHolidays,
  };
}

export const INCLUDE_WEEKENDS_AND_HOLIDAYS_OPERATION_KEY = 'useIncludeWeekendsAndHolidays_operation';
export function useIncludeWeekendsAndHolidays(
  base$key: SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment$key | null | undefined,
  sale$key: SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment$key | null | undefined,
  disabled: boolean,
) {
  const env = useRelayEnvironment();

  const base$data = useFragment(
    graphql`
      fragment SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment on ProjectInternalBase
      @argumentDefinitions(isCopy: { type: "Boolean!" }) {
        ...ProjectBaseFields_ArrivalDateReadFragment @arguments(isCopy: $isCopy)
      }
    `,
    base$key,
  );

  const sale$data = useFragment(
    graphql`
      fragment SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment on ISaleProject {
        ...SaleProjectFields_IncludeWeekendsAndHolidaysFragment
      }
    `,
    sale$key,
  );

  const { setIncludeWeekendsAndHolidays, includeWeekendsAndHolidays } = useFieldIncludeWeekendsAndHolidays(sale$data, disabled);
  const { arrivalDate, arrivalDateIsDirty } = useFieldArrivalDateRead(base$data);

  const { startOperation, endOperation } = useOperations(INCLUDE_WEEKENDS_AND_HOLIDAYS_OPERATION_KEY);
  const { resetError, setError } = useOperationsError(INCLUDE_WEEKENDS_AND_HOLIDAYS_OPERATION_KEY);

  const [_, setSubscription] = useCancellableSubscription();

  const fetchDateInfo = useEffectEvent((variables: SaleProjectFields_useIncludeWeekendsAndHolidaysQuery$variables) => {
    startOperation();
    resetError();

    setSubscription(
      fetchQuery<SaleProjectFields_useIncludeWeekendsAndHolidaysQuery>(
        env,
        graphql`
          query SaleProjectFields_useIncludeWeekendsAndHolidaysQuery($date: Date!) {
            dateInfo(date: $date) {
              isStatutoryHoliday
              isWeekend
            }
          }
        `,
        variables,
      ).subscribe({
        error: (error: Error) => {
          Sentry.captureException(error);
          endOperation();
          setError(error);
        },
        next: (result) => {
          // Contrary to many other usages of the fetchQuery pattern, we do not need to manually retain query
          // for this usage since once we set the field, we don't need query result anymore.

          const isDateWeekendOrHoliday = result.dateInfo.isWeekend || result.dateInfo.isStatutoryHoliday;

          // Set the field value to the queried one if it is different
          if (isDateWeekendOrHoliday !== includeWeekendsAndHolidays) {
            setIncludeWeekendsAndHolidays(isDateWeekendOrHoliday);
          }

          endOperation();
        },
        unsubscribe: () => {
          endOperation();
          resetError();
        },
      }),
    );
  });

  useEffect(() => {
    if (arrivalDate && arrivalDateIsDirty) {
      const date = arrivalDate.toISODate();
      if (date) {
        fetchDateInfo({ date });
      }
    }
  }, [arrivalDate, arrivalDateIsDirty, fetchDateInfo]);
}

function LoadingCheckboxInput({ labelKey }: { labelKey: string }) {
  const { t } = useAmbientTranslation();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CircularProgress size={18} sx={{ m: '0.75rem', ml: '0.0625rem' }} />
      <Typography>{t(labelKey)}</Typography>
    </Box>
  );
}
