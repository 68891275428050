import { Suspense, useCallback } from 'react';
import { createSearchParams, useNavigate } from 'react-router';
import { Skeleton, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import { QuoteList, QuoteListProps } from './QuoteList';
import graphql from 'babel-plugin-relay/macro';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import { EmptyLayout, ListLayout, ListLayoutActions, SidebarContentProps } from '../layout/Layouts';
import { QuoteListPageContentQuery, QuoteListPageContentQuery$variables } from './__generated__/QuoteListPageContentQuery.graphql';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { RequireRead, RequireWrite, UnauthorizedFallback } from '../auth/Authorization';
import { NavigationMenu } from '../layout/SidebarDrawer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { QuoteKind } from '../__enums__/QuoteKind';
import { ListPageErrorBoundary } from '../layout/ListPageErrorBoundary';
import { ListPageRootErrorBoundary } from '../layout/ListPageRootErrorBoundary';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { DateTime } from 'luxon';
import { EmployeeNotFoundError, EmployeeNotFoundErrorBoundary } from '../auth/EmployeeNotFoundErrorBoundary';
import { QuoteListPageContentDefaultFiltersFragment$key } from './__generated__/QuoteListPageContentDefaultFiltersFragment.graphql';
import { QuoteListPageRootQuery } from './__generated__/QuoteListPageRootQuery.graphql';
import { useJobFilters, useJobFiltersSearchParams } from '../jobs/JobFilters';
import {
  Assignment,
  AssignmentLate,
  CalendarToday,
  EngineeringOutlined,
  HdrAuto,
  Person,
  PersonOutline,
  Schedule,
  Warehouse,
} from '@mui/icons-material';
import { createSharedStateKey } from '../common/utils/sharedState';
import { QuoteFilters } from './QuoteFilters';
import { useConstantValue } from '../common/hooks/useConstantValue';

const flagName = 'app_navigation_quote';
export function QuoteListPage() {
  const { t } = useAmbientTranslation();

  return (
    <ListPageErrorBoundary heading={t('quotes')} flagName={flagName}>
      <EmployeeNotFoundErrorBoundary>
        <Suspense fallback={<QuoteListPageSkeleton />}>
          <QuoteListPageRoot />
        </Suspense>
      </EmployeeNotFoundErrorBoundary>
    </ListPageErrorBoundary>
  );
}

function QuoteListPageSkeleton() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return <EmptyLayout>{compact ? <ListSkeleton /> : <GridSkeleton />}</EmptyLayout>;
}

function QuoteListPageRoot() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const $data = useLazyLoadQuery<QuoteListPageRootQuery>(
    graphql`
      query QuoteListPageRootQuery {
        ...AuthorizationReadFragment
        ...AuthorizationWriteFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
        ...ListPageRootErrorBoundaryFragment
        me {
          ...QuoteListPageContentDefaultFiltersFragment
        }
      }
    `,
    {},
  );

  const { t } = useAmbientTranslation();
  usePageTitle(namedPageTitle('quotes'));

  const navigate = useNavigate();

  const handleItemCreate = useCallback(
    (kind: QuoteKind) =>
      navigate({
        pathname: `/quotes/new`,
        search: createSearchParams({ kind }).toString(),
      }),
    [navigate],
  );

  const handleItemClick = useCallback((id: DataID) => navigate(`/quotes/${id}`), [navigate]);

  const theme = useTheme();
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const heading = t('quotes');

  if (!$data.me) throw new EmployeeNotFoundError();

  return (
    <ListPageRootErrorBoundary $key={$data} heading={heading} flagName={flagName}>
      <RequireRead
        $key={$data}
        fallback={
          <ListLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
            <UnauthorizedFallback />
          </ListLayout>
        }>
        <ListLayout
          heading={heading}
          flagName={flagName}
          sidebarProvider={sidebar}
          $key={$data}
          actions={
            <RequireWrite $key={$data}>
              <ListLayoutActions
                mainAction={{
                  label: t('kind.operatedHourly'),
                  icon: <Schedule />,
                  onClick: () => handleItemCreate('operatedHourly'),
                }}
                otherActions={{
                  operatedMonthly: {
                    label: t('kind.operatedMonthly'),
                    icon: <CalendarToday />,
                    onClick: () => handleItemCreate('operatedMonthly'),
                  },
                  bare: {
                    label: t('kind.bare'),
                    icon: <PersonOutline />,
                    onClick: () => handleItemCreate('bare'),
                  },
                  bareWithOperators: {
                    label: compact ? t('kindShort.bareWithOperators') : t('kind.bareWithOperators'),
                    icon: <EngineeringOutlined />,
                    onClick: () => handleItemCreate('bareWithOperators'),
                  },
                  accessoriesRental: {
                    label: t('kind.accessoriesRental'),
                    icon: <HdrAuto />,
                    onClick: () => handleItemCreate('accessoriesRental'),
                  },
                  laborRental: {
                    label: t('kind.laborRental'),
                    icon: <Person />,
                    onClick: () => handleItemCreate('laborRental'),
                  },
                  rollingEquipment: {
                    label: t('kind.rollingEquipment'),
                    icon: <LocalShippingIcon />,
                    onClick: () => handleItemCreate('rollingEquipment'),
                  },
                  liftingPlan: {
                    label: t('kind.liftingPlan'),
                    icon: <Assignment />,
                    onClick: () => handleItemCreate('liftingPlan'),
                  },
                  liftingTest: {
                    label: t('kind.liftingTest'),
                    icon: <AssignmentLate />,
                    onClick: () => handleItemCreate('liftingTest'),
                  },
                  storage: {
                    label: t('kind.storage'),
                    icon: <Warehouse />,
                    onClick: () => handleItemCreate('storage'),
                  },
                }}
                componentProps={{
                  autoSplitButton: {
                    root: { 'aria-label': t('ariaLabels.root') },
                    buttonMain: { 'aria-label': t('ariaLabels.main') },
                    buttonMenu: { 'aria-label': t('ariaLabels.more') },
                    menuItemOption: ([, a]) => ({ 'aria-label': a.label }),
                  },
                  autoSpeedDial: {
                    root: {
                      'aria-label': 'ariaLabels.more',
                      ariaLabel: t('ariaLabels.more'),
                    },
                    speedDialActionOption: ([, a]) => ({ 'aria-label': a.label }),
                    dialog: {
                      title: t('selectKind', { ns: 'jobs' }),
                    },
                  },
                }}
              />
            </RequireWrite>
          }>
          <Suspense fallback={compact ? <ListSkeleton /> : <GridSkeleton />}>
            <QuoteListPageContent
              defaultFilters$key={$data.me}
              write$key={$data}
              compact={compact}
              paginationSx={{ [theme.breakpoints.down('sm')]: { mb: '6rem' } }}
              onItemClick={handleItemClick}
            />
          </Suspense>
        </ListLayout>
      </RequireRead>
    </ListPageRootErrorBoundary>
  );
}

const quoteFiltersSharedStateKey = createSharedStateKey<QuoteFilters | null>(() => null);

export function useQuoteListPageUrl() {
  const searchParams = useJobFiltersSearchParams(quoteFiltersSharedStateKey);
  return `/quotes${searchParams}`;
}

function QuoteListPageContent({
  defaultFilters$key,
  ...props
}: {
  defaultFilters$key: QuoteListPageContentDefaultFiltersFragment$key;
} & Omit<QuoteListProps, '$key' | 'filters$key' | 'filters' | 'onFiltersChange'>) {
  const defaultFilters$data = useFragment(
    graphql`
      fragment QuoteListPageContentDefaultFiltersFragment on Employee {
        representativeId
        roles
        ...JobFilters_useJobFiltersFragment
      }
    `,
    defaultFilters$key,
  );

  const salesDefaultFilters = useConstantValue(() => {
    const today = DateTime.now().startOf('day');
    const isSalesRepresentative = defaultFilters$data.roles.includes('salesRepresentative');
    return QuoteFilters.EMPTY.with({
      arrivalDate: { start: today, end: today.plus({ day: 90 }) },
      statuses: isSalesRepresentative ? ['inApproval', 'inWriting', 'revising', 'awaitingClient'] : ['inApproval'],
      projectManagers: isSalesRepresentative && defaultFilters$data.representativeId ? [{ id: defaultFilters$data.representativeId }] : [],
    });
  });

  const [filters, handleFiltersChange] = useJobFilters<QuoteFilters>(
    defaultFilters$data,
    quoteFiltersSharedStateKey,
    QuoteFilters.EMPTY,
    salesDefaultFilters,
    (sp) => QuoteFilters.fromSearchParams(sp),
  );

  const queryFilters = useConstantValue<QuoteListPageContentQuery$variables>(() => ({
    first: 25,
    where: filters.toJobRevisionFilter(),
    searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
    projectManagerIds: filters.get('projectManagers').map(({ id }) => id),
    dispatchBranchIds: filters.get('dispatchBranches').map(({ id }) => id),
    equipmentKindCodes: filters.get('equipmentKinds').map(({ code }) => code),
    representativeIds: filters.get('representatives').map(({ id }) => id),
  }));

  const quotes = useLazyLoadQuery<QuoteListPageContentQuery>(
    graphql`
      query QuoteListPageContentQuery(
        $first: Int
        $where: QuoteJobRevisionFilterType
        $dispatchBranchIds: [ID!]!
        $equipmentKindCodes: [Int!]!
        $projectManagerIds: [ID!]!
        $representativeIds: [ID!]!
        $searchTerm: String
      ) {
        ...QuoteListFragment @arguments(searchTerm: $searchTerm, representativeIds: $representativeIds, first: $first, where: $where)
        ...QuoteListFiltersFragment
          @arguments(
            dispatchBranchIds: $dispatchBranchIds
            equipmentKindCodes: $equipmentKindCodes
            projectManagerIds: $projectManagerIds
            representativeIds: $representativeIds
          )
      }
    `,
    queryFilters,
    { fetchPolicy: 'store-and-network' },
  );

  return <QuoteList {...props} $key={quotes} filters$key={quotes} filters={filters} onFiltersChange={handleFiltersChange} />;
}

function GridSkeleton() {
  return (
    <Stack gap='1rem'>
      <Skeleton variant='rounded' height='3rem' />
      <Skeleton variant='rounded' height='40rem' />
    </Stack>
  );
}

function ListSkeleton() {
  return (
    <Stack gap='1rem'>
      <Skeleton variant='rounded' height='3rem' />
      <Stack gap={0.5}>
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
      </Stack>
    </Stack>
  );
}
