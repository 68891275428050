import { Suspense, useCallback } from 'react';
import { createSearchParams, useNavigate } from 'react-router';
import { Skeleton, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import { ServiceCallList, ServiceCallListProps } from './ServiceCallList';
import graphql from 'babel-plugin-relay/macro';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import { EmptyLayout, ListLayout, ListLayoutActions, SidebarContentProps } from '../layout/Layouts';
import { ServiceCallKind } from '../__enums__/ServiceCallKind';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { RequireRead, RequireWrite, UnauthorizedFallback } from '../auth/Authorization';
import {
  ServiceCallListPageContentQuery,
  ServiceCallListPageContentQuery$variables,
} from './__generated__/ServiceCallListPageContentQuery.graphql';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { ServiceCallListPageContentDefaultFiltersFragment$key } from './__generated__/ServiceCallListPageContentDefaultFiltersFragment.graphql';
import { EmployeeNotFoundError, EmployeeNotFoundErrorBoundary } from '../auth/EmployeeNotFoundErrorBoundary';
import { DateTime } from 'luxon';
import { ListPageErrorBoundary } from '../layout/ListPageErrorBoundary';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { ListPageRootErrorBoundary } from '../layout/ListPageRootErrorBoundary';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { ServiceCallListPageRootQuery } from './__generated__/ServiceCallListPageRootQuery.graphql';
import { useJobFilters, useJobFiltersSearchParams } from '../jobs/JobFilters';
import {
  Assignment,
  AssignmentLate,
  CalendarToday,
  EngineeringOutlined,
  HdrAuto,
  Person,
  PersonAddAlt,
  PersonAddAlt1,
  PersonOutline,
  Schedule,
  Warehouse,
} from '@mui/icons-material';
import { createSharedStateKey } from '../common/utils/sharedState';
import { ServiceCallFilters } from './ServiceCallFilters';
import { useConstantValue } from '../common/hooks/useConstantValue';

const flagName = 'app_navigation_service_calls';

export function ServiceCallListPage() {
  const { t } = useAmbientTranslation();

  return (
    <ListPageErrorBoundary heading={t('pages.list.title')} flagName={flagName}>
      <EmployeeNotFoundErrorBoundary>
        <Suspense fallback={<ServiceCallListPageSkeleton />}>
          <ServiceCallListPageRoot />
        </Suspense>
      </EmployeeNotFoundErrorBoundary>
    </ListPageErrorBoundary>
  );
}

function ServiceCallListPageSkeleton() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return <EmptyLayout>{compact ? <ListSkeleton /> : <GridSkeleton />}</EmptyLayout>;
}

function ServiceCallListPageRoot() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const $data = useLazyLoadQuery<ServiceCallListPageRootQuery>(
    graphql`
      query ServiceCallListPageRootQuery {
        ...AuthorizationReadFragment
        ...AuthorizationWriteFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
        ...ListPageRootErrorBoundaryFragment
        me {
          ...ServiceCallListPageContentDefaultFiltersFragment
        }
      }
    `,
    {},
  );

  const { t } = useAmbientTranslation();
  usePageTitle(namedPageTitle('sidebar.serviceCalls'));

  const theme = useTheme();
  const navigate = useNavigate();

  const handleItemCreate = useCallback(
    (kind: ServiceCallKind) =>
      navigate({
        pathname: `/service-calls/new`,
        search: createSearchParams({ kind }).toString(),
      }),
    [navigate],
  );
  const handleItemClick = useCallback((id: DataID) => navigate(`/service-calls/${id}`), [navigate]);
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const heading = t('pages.list.title');

  if (!$data.me) throw new EmployeeNotFoundError();

  return (
    <ListPageRootErrorBoundary $key={$data} heading={heading} flagName={flagName}>
      <RequireRead
        $key={$data}
        fallback={
          <ListLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
            <UnauthorizedFallback />
          </ListLayout>
        }>
        <ListLayout
          heading={heading}
          flagName={flagName}
          sidebarProvider={sidebar}
          $key={$data}
          actions={
            <RequireWrite $key={$data}>
              <ServiceCallListLayoutActions onItemClick={handleItemCreate} />
            </RequireWrite>
          }>
          <Suspense fallback={compact ? <ListSkeleton /> : <GridSkeleton />}>
            <ServiceCallListPageContent
              defaultFilters$key={$data.me}
              write$key={$data}
              compact={compact}
              onItemClick={handleItemClick}
              paginationSx={{ [theme.breakpoints.down('sm')]: { mb: '6rem' } }}
            />
          </Suspense>
        </ListLayout>
      </RequireRead>
    </ListPageRootErrorBoundary>
  );
}

const serviceCallFiltersSharedStateKey = createSharedStateKey<ServiceCallFilters | null>(() => null);

export function useServiceCallListPageUrl() {
  const searchParams = useJobFiltersSearchParams(serviceCallFiltersSharedStateKey);
  return `/service-calls${searchParams}`;
}

function ServiceCallListPageContent({
  defaultFilters$key,
  ...listProps
}: {
  defaultFilters$key: ServiceCallListPageContentDefaultFiltersFragment$key;
} & Omit<ServiceCallListProps, '$key' | 'filters$key' | 'filters' | 'onFiltersChange'>) {
  const $data = useFragment(
    graphql`
      fragment ServiceCallListPageContentDefaultFiltersFragment on Employee {
        representativeId
        roles
        ...JobFilters_useJobFiltersFragment
      }
    `,
    defaultFilters$key,
  );

  const salesDefaultFilters = useConstantValue(() => {
    const today = DateTime.now().startOf('day');
    const isSalesRepresentative = $data.roles.includes('salesRepresentative');
    return ServiceCallFilters.EMPTY.with({
      arrivalDate: { start: today, end: today.plus({ day: 90 }) },
      statuses: ['inWriting', 'reserved', 'inModification', 'delayed', 'transferred'],
      projectManagers: isSalesRepresentative && $data.representativeId ? [{ id: $data.representativeId }] : [],
    });
  });

  const [filters, handleFiltersChange] = useJobFilters<ServiceCallFilters>(
    $data,
    serviceCallFiltersSharedStateKey,
    ServiceCallFilters.EMPTY,
    salesDefaultFilters,
    (sp) => ServiceCallFilters.fromSearchParams(sp),
  );

  const queryFilters = useConstantValue<ServiceCallListPageContentQuery$variables>(() => ({
    first: 25,
    where: filters.toJobRevisionFilter(),
    searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
    projectManagerIds: filters.get('projectManagers').map(({ id }) => id),
    dispatchBranchIds: filters.get('dispatchBranches').map(({ id }) => id),
    equipmentKindCodes: filters.get('equipmentKinds').map(({ code }) => code),
    representativeIds: filters.get('representatives').map(({ id }) => id),
  }));

  const serviceCalls = useLazyLoadQuery<ServiceCallListPageContentQuery>(
    graphql`
      query ServiceCallListPageContentQuery(
        $searchTerm: String
        $first: Int
        $where: ServiceCallJobRevisionFilterType
        $dispatchBranchIds: [ID!]!
        $equipmentKindCodes: [Int!]!
        $projectManagerIds: [ID!]!
        $representativeIds: [ID!]!
      ) {
        ...ServiceCallListFragment @arguments(searchTerm: $searchTerm, representativeIds: $representativeIds, first: $first, where: $where)
        ...ServiceCallListFiltersFragment
          @arguments(
            dispatchBranchIds: $dispatchBranchIds
            equipmentKindCodes: $equipmentKindCodes
            projectManagerIds: $projectManagerIds
            representativeIds: $representativeIds
          )
      }
    `,
    queryFilters,
    { fetchPolicy: 'store-and-network' },
  );

  return (
    <ServiceCallList
      {...listProps}
      $key={serviceCalls}
      filters$key={serviceCalls}
      filters={filters}
      onFiltersChange={handleFiltersChange}
    />
  );
}

function GridSkeleton() {
  return (
    <Stack gap='1rem'>
      <Skeleton variant='rounded' height='3rem' />
      <Skeleton variant='rounded' height='40rem' />
    </Stack>
  );
}

function ListSkeleton() {
  return (
    <Stack gap='1rem'>
      <Skeleton variant='rounded' height='3rem' />
      <Stack gap={0.5}>
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
      </Stack>
    </Stack>
  );
}

export function ServiceCallListLayoutActions({ onItemClick: handleItemClick }: { onItemClick: (kind: ServiceCallKind) => void }) {
  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <ListLayoutActions
      mainAction={{
        label: t('kind.operatedHourly'),
        icon: <Schedule />,
        onClick: () => handleItemClick('operatedHourly'),
      }}
      otherActions={{
        operatedMonthly: {
          label: t('kind.operatedMonthly'),
          icon: <CalendarToday />,
          onClick: () => handleItemClick('operatedMonthly'),
        },
        bare: {
          label: t('kind.bare'),
          icon: <PersonOutline />,
          onClick: () => handleItemClick('bare'),
        },
        bareWithOperators: {
          label: compact ? t('kindShort.bareWithOperators') : t('kind.bareWithOperators'),
          icon: <EngineeringOutlined />,
          onClick: () => handleItemClick('bareWithOperators'),
        },
        accessoriesRental: {
          label: t('kind.accessoriesRental'),
          icon: <HdrAuto />,
          onClick: () => handleItemClick('accessoriesRental'),
        },
        laborRental: {
          label: t('kind.laborRental'),
          icon: <Person />,
          onClick: () => handleItemClick('laborRental'),
        },
        laborRentalOnOperated: {
          label: compact ? t('kindShort.laborRentalOnOperated') : t('kind.laborRentalOnOperated'),
          icon: <PersonAddAlt1 />,
          onClick: () => handleItemClick('laborRentalOnOperated'),
        },
        laborRentalOnBare: {
          label: compact ? t('kindShort.laborRentalOnBare') : t('kind.laborRentalOnBare'),
          icon: <PersonAddAlt />,
          onClick: () => handleItemClick('laborRentalOnBare'),
        },
        rollingEquipment: {
          label: t('kind.rollingEquipment'),
          icon: <LocalShippingIcon />,
          onClick: () => handleItemClick('rollingEquipment'),
        },
        liftingPlan: {
          label: t('kind.liftingPlan'),
          icon: <Assignment />,
          onClick: () => handleItemClick('liftingPlan'),
        },
        liftingTest: {
          label: t('kind.liftingTest'),
          icon: <AssignmentLate />,
          onClick: () => handleItemClick('liftingTest'),
        },
        storage: {
          label: t('kind.storage'),
          icon: <Warehouse />,
          onClick: () => handleItemClick('storage'),
        },
      }}
      componentProps={{
        autoSplitButton: {
          root: { 'aria-label': t('ariaLabels.root') },
          buttonMain: { 'aria-label': t('ariaLabels.main') },
          buttonMenu: { 'aria-label': t('ariaLabels.more') },
          menuItemOption: ([, a]) => ({ 'aria-label': a.label }),
        },
        autoSpeedDial: {
          root: {
            'aria-label': 'arialLabels.more',
            ariaLabel: t('ariaLabels.more'),
          },
          speedDialActionOption: ([, a]) => ({ 'aria-label': a.label }),
          dialog: {
            title: t('selectKind', { ns: 'jobs' }),
          },
        },
      }}
    />
  );
}
