import {
  createFieldKey,
  createFormContext,
  SetValueFn,
  useField,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useCallback } from 'react';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { ForwardWorkScheduleAutocompleteProps, WorkScheduleAutocomplete } from '../../common/components/WorkScheduleAutocomplete';
import {
  ForwardNatureOfWorkSubCategoryAutocompleteProps,
  NatureOfWorkSubCategoryAutocomplete,
} from '../../common/components/NatureOfWorkSubCategoryAutocomplete';
import { NatureOfWorkFields_WorkSchedulesFragment$key } from './__generated__/NatureOfWorkFields_WorkSchedulesFragment.graphql';
import { castWorkScheduleEnum, WorkScheduleEnum } from '../../__enums__/WorkScheduleEnum';
import { NatureOfWorkFields_CategoriesFragment$key } from './__generated__/NatureOfWorkFields_CategoriesFragment.graphql';
import { castNatureOfWorkSubCategoryEnum } from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { NatureOfWorkFields_DefaultWorkScheduleFragment$key } from './__generated__/NatureOfWorkFields_DefaultWorkScheduleFragment.graphql';
import { _throw } from '../../common/utils/_throw';

export interface NatureOfWorkFieldsMappings {}
export const natureOfWorkFormContext = createFormContext<NatureOfWorkFieldsMappings>();

type FieldNatureOfWorkWorkSchedules = NonNullable<ForwardWorkScheduleAutocompleteProps<true>['value']>;
const fieldWorkSchedulesKey = createFieldKey<FieldNatureOfWorkWorkSchedules>();
export function useFieldNatureOfWorkWorkSchedules($key: NatureOfWorkFields_WorkSchedulesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment NatureOfWorkFields_WorkSchedulesFragment on NatureOfWorkLookup {
        workSchedules
      }
    `,
    $key,
  );

  const [workSchedules, setWorkSchedules] = useField(
    natureOfWorkFormContext,
    fieldWorkSchedulesKey,
    () => $data?.workSchedules.map((x) => castWorkScheduleEnum(x)) ?? [],
  );
  const workSchedulesAreDirty = useFieldIsDirty(natureOfWorkFormContext, fieldWorkSchedulesKey);

  const useValidation = useFieldValidation(natureOfWorkFormContext, fieldWorkSchedulesKey);
  useValidation((v) => v.length > 0, [], 'save:required');

  const useMapper = useFieldMapper(natureOfWorkFormContext, fieldWorkSchedulesKey);
  useMapper((val) => ({ workSchedules: val }), [], 'save');

  const renderWorkSchedules = useCallback(
    () => <WorkSchedulesInput setValue={setWorkSchedules} value={workSchedules} />,
    [setWorkSchedules, workSchedules],
  );

  return { workSchedules, workSchedulesAreDirty, setWorkSchedules, renderWorkSchedules };
}

function WorkSchedulesInput({
  setValue,
  value,
}: {
  value: FieldNatureOfWorkWorkSchedules;
  setValue: SetValueFn<FieldNatureOfWorkWorkSchedules>;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(natureOfWorkFormContext, fieldWorkSchedulesKey);

  const handleChange = useCallback<NonNullable<ForwardWorkScheduleAutocompleteProps<true>['onChange']>>(
    (_, v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <WorkScheduleAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      textFieldProps={() => ({
        label: t('natureOfWork.field.workSchedules'),
        error: hasErrors,
        required: true,
      })}
    />
  );
}

type FieldNatureOfWorkDefaultWorkSchedule = ForwardWorkScheduleAutocompleteProps['value'] | null;
const fieldDefaultWorkScheduleKey = createFieldKey<FieldNatureOfWorkDefaultWorkSchedule>();
export function useFieldNatureOfWorkDefaultWorkSchedule($key: NatureOfWorkFields_DefaultWorkScheduleFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment NatureOfWorkFields_DefaultWorkScheduleFragment on NatureOfWorkLookup {
        defaultWorkSchedule
      }
    `,
    $key,
  );

  const [workSchedule, setWorkSchedule] = useField(natureOfWorkFormContext, fieldDefaultWorkScheduleKey, () =>
    $data?.defaultWorkSchedule ? castWorkScheduleEnum($data.defaultWorkSchedule) : null,
  );
  const useValidation = useFieldValidation(natureOfWorkFormContext, fieldDefaultWorkScheduleKey);
  useValidation((v) => !!v, [], 'save:required');

  const useMapper = useFieldMapper(natureOfWorkFormContext, fieldDefaultWorkScheduleKey);
  useMapper((val) => ({ defaultWorkSchedule: val ?? _throw('Default work schedule is required') }), [], 'save');

  const renderDefaultWorkSchedule = useCallback(
    (options: WorkScheduleEnum[]) => <WorkScheduleInput value={workSchedule} setValue={setWorkSchedule} options={options} />,
    [setWorkSchedule, workSchedule],
  );

  return { workSchedule, setWorkSchedule, renderDefaultWorkSchedule };
}

function WorkScheduleInput({
  setValue,
  value,
  options,
}: {
  value: FieldNatureOfWorkDefaultWorkSchedule;
  setValue: SetValueFn<FieldNatureOfWorkDefaultWorkSchedule>;
  options: WorkScheduleEnum[];
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(natureOfWorkFormContext, fieldDefaultWorkScheduleKey);

  const handleChange = useCallback<NonNullable<ForwardWorkScheduleAutocompleteProps['onChange']>>(
    (_, v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <WorkScheduleAutocomplete
      value={value}
      onChange={handleChange}
      options={options}
      textFieldProps={() => ({
        label: t('natureOfWork.field.defaultWorkSchedule'),
        error: hasErrors,
        required: true,
      })}
    />
  );
}

type FieldNatureOfWorkCategories = NonNullable<ForwardNatureOfWorkSubCategoryAutocompleteProps<true>['value']>;
const fieldCategoriesKey = createFieldKey<FieldNatureOfWorkCategories>();
export function useFieldNatureOfWorkCategories($key: NatureOfWorkFields_CategoriesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment NatureOfWorkFields_CategoriesFragment on NatureOfWorkLookup {
        natureOfWorkSubCategories
      }
    `,
    $key,
  );

  const [categories, setCategories] = useField(
    natureOfWorkFormContext,
    fieldCategoriesKey,
    () => $data?.natureOfWorkSubCategories.map((x) => castNatureOfWorkSubCategoryEnum(x)) ?? [],
  );
  const useValidation = useFieldValidation(natureOfWorkFormContext, fieldCategoriesKey);
  useValidation((v) => v.length > 0, [], 'save:required');

  const useMapper = useFieldMapper(natureOfWorkFormContext, fieldCategoriesKey);
  useMapper((val) => ({ natureOfWorkSubCategories: val }), [], 'save');

  const renderCategories = useCallback(() => <CategoriesInput value={categories} setValue={setCategories} />, [categories, setCategories]);

  return { categories, setCategories, renderCategories };
}

function CategoriesInput({ setValue, value }: { value: FieldNatureOfWorkCategories; setValue: SetValueFn<FieldNatureOfWorkCategories> }) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(natureOfWorkFormContext, fieldCategoriesKey);

  const handleChange = useCallback<NonNullable<ForwardNatureOfWorkSubCategoryAutocompleteProps<true>['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <NatureOfWorkSubCategoryAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`natureOfWorkSubCategories.${o}`, { ns: 'common' })}
      textFieldProps={() => ({
        label: t('natureOfWork.field.categories'),
        error: hasErrors,
        required: true,
      })}
    />
  );
}
