import { forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps, useMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { useFlag } from '@unleash/proxy-client-react';
import { useRefSync } from '../common/hooks/useRefSync';
import { useSticky } from '../common/hooks/useSticky';
import { usePreviousValue } from '../common/hooks/usePreviousValue';
import CallIcon from '@mui/icons-material/Call';
import Close from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import StarIcon from '@mui/icons-material/Star';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { RequireAdmin, RequireCraneChartMaintainer } from '../auth/Authorization';
import { SidebarDrawerFragment$key } from './__generated__/SidebarDrawerFragment.graphql';
import RuleIcon from '@mui/icons-material/Rule';
import { TableChart } from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';
import { useWorkPlanningListPageUrl } from '../workPlanning/WorkPlanningListPage';
import { useQuoteListPageUrl } from '../quote/QuoteListPage';
import { useServiceCallListPageUrl } from '../serviceCall/ServiceCallListPage';

export type SidebarVariant = 'temporary' | 'permanent';

/**
 * Generates SxProps for sidebar animation based on the target variant and open state.
 *
 * @param targetVariant The target variant of the sidebar.
 * @param open Indicates whether the sidebar is open or not.
 * @param states Object containing the different states of the sidebar for which animations are necessary.
 * @param cssProps CSS properties to apply animation on.
 * @returns A tuple of the animation styles and duration.
 */
export function useSidebarAnimation(
  targetVariant: SidebarVariant,
  open: boolean,
  states: { dialog?: SystemStyleObject; none?: SystemStyleObject; narrow?: SystemStyleObject; wide?: SystemStyleObject },
  cssProps: string[],
) {
  const theme = useTheme();

  const statesRef = useRefSync({ dialog: {}, none: {}, narrow: {}, wide: {}, ...states });
  const cssPropsRef = useRefSync(cssProps);

  const [animationDuration, setAnimationDuration] = useState(0);
  const [animationSx, setAnimationSx] = useState<SystemStyleObject>(() => {
    if (targetVariant === 'temporary' && open) {
      return statesRef.current.dialog;
    }
    if (targetVariant === 'temporary' && !open) {
      return statesRef.current.none;
    }
    if (targetVariant === 'permanent' && !open) {
      return statesRef.current.narrow;
    }
    if (targetVariant === 'permanent' && open) {
      return statesRef.current.wide;
    }
    return {};
  });

  const previousTargetVariantRef = useRefSync(usePreviousValue(targetVariant, targetVariant));
  const previousOpenRef = useRefSync(usePreviousValue(open, open));

  useEffect(() => {
    const prevTargetVariant = previousTargetVariantRef.current;
    const prevOpen = previousOpenRef.current;

    const prevDialog = prevTargetVariant === 'temporary' && prevOpen;
    const prevNone = prevTargetVariant === 'temporary' && !prevOpen;
    const prevNarrow = prevTargetVariant === 'permanent' && !prevOpen;
    const prevWide = prevTargetVariant === 'permanent' && prevOpen;

    const dialog = targetVariant === 'temporary' && open;
    const none = targetVariant === 'temporary' && !open;
    const narrow = targetVariant === 'permanent' && !open;
    const wide = targetVariant === 'permanent' && open;

    if (prevDialog && none) {
      setAnimationSx(statesRef.current.none);
      setAnimationDuration(0);
    } else if (prevDialog && narrow) {
      setAnimationSx(statesRef.current.narrow);
      setAnimationDuration(0);
    } else if (prevDialog && wide) {
      setAnimationSx(statesRef.current.wide);
      setAnimationDuration(0);
    } else if (prevNone && dialog) {
      setAnimationSx(statesRef.current.dialog);
      setAnimationDuration(0);
    } else if (prevNone && narrow) {
      setAnimationSx(statesRef.current.narrow);
      setAnimationDuration(theme.transitions.duration.enteringScreen);
    } else if (prevNone && wide) {
      setAnimationSx(statesRef.current.wide);
      setAnimationDuration(theme.transitions.duration.enteringScreen);
    } else if (prevNarrow && dialog) {
      setAnimationSx(statesRef.current.dialog);
      setAnimationDuration(0);
    } else if (prevNarrow && none) {
      setAnimationSx(statesRef.current.none);
      setAnimationDuration(theme.transitions.duration.leavingScreen);
    } else if (prevNarrow && wide) {
      setAnimationSx(statesRef.current.wide);
      setAnimationDuration(theme.transitions.duration.enteringScreen);
    } else if (prevWide && dialog) {
      setAnimationSx(statesRef.current.dialog);
      setAnimationDuration(0);
    } else if (prevWide && none) {
      setAnimationSx(statesRef.current.none);
      setAnimationDuration(theme.transitions.duration.leavingScreen);
    } else if (prevWide && narrow) {
      setAnimationSx(statesRef.current.narrow);
      setAnimationDuration(theme.transitions.duration.leavingScreen);
    }
  }, [
    targetVariant,
    open,
    statesRef,
    previousTargetVariantRef,
    previousOpenRef,
    theme.transitions.duration.enteringScreen,
    theme.transitions.duration.leavingScreen,
  ]);

  const transformSx = useMemo(
    () => ({
      transition: theme.transitions.create(cssPropsRef.current, {
        easing: theme.transitions.easing.sharp,
        duration: animationDuration,
      }),
    }),
    [animationDuration, cssPropsRef, theme.transitions],
  );

  const sx = useMemo(() => ({ ...animationSx, ...transformSx }), [animationSx, transformSx]);

  return [sx, animationDuration] as const;
}

interface SidebarDrawerProps {
  targetVariant: SidebarVariant;
  open: boolean;
  onDismiss: () => void;
  children?: ReactNode;
}

/**
 * Renders the main application's sidebar with various states and animations.
 *
 * The dialog and none states are used for the temporary variant.
 * The narrow and wide states are used for the permanent variant.
 * @param targetVariant Sets the sidebar in 'temporary' or 'permanent' mode.
 * @param open Controls whether the sidebar should be in a dialog/wide state, or none/narrow state.
 * @param onDismiss A callback that triggers when the sidebar is dismissed from its dialog state.
 * @constructor
 */
export function SidebarDrawer({ targetVariant, open, onDismiss: handleDismiss, children }: SidebarDrawerProps) {
  const theme = useTheme();

  // Produce an animation state
  const [animationSx, animationDuration] = useSidebarAnimation(
    targetVariant,
    open,
    {
      none: {
        width: theme.mixins.drawer.width.narrow,
        transform: `translateX(calc(-1px - ${theme.mixins.drawer.width.narrow}))`,
      },
      narrow: {
        width: theme.mixins.drawer.width.narrow,
      },
      wide: {
        width: theme.mixins.drawer.width.wide,
      },
    },
    ['width', 'transform'],
  );

  const activeVariant = useSticky(targetVariant, animationDuration, (v) => v === 'permanent');
  const handleTemporaryDismiss = activeVariant === 'temporary' ? handleDismiss : undefined;

  return (
    <Drawer
      variant={activeVariant}
      open={open}
      onClose={handleTemporaryDismiss}
      sx={{
        ...(activeVariant === 'permanent' && {
          // Render full height
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100dvh',
          // Render after AppBar.
          mt: theme.mixins.toolbar.minHeight,
          // Include a small padding to indicate that there is no more content when scrolling
          pb: '1.5rem',
        }),
      }}
      PaperProps={{
        sx: {
          // Visually hide the Drawer's content when closed.
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
          // Force a content width when floating.
          minWidth: activeVariant === 'temporary' ? theme.mixins.drawer.width.wide : 'auto',
          // Force the drawer to render in the page's context.
          position: activeVariant === 'temporary' ? 'fixed' : 'static',

          ...animationSx,
        },
      }}>
      {activeVariant === 'temporary' && (
        // Use the same display mechanism as actual items in NavigationMenu to ensure consistent positioning.
        <ListItem sx={{ pt: '1rem', pb: 0 }}>
          <ListItemIcon onClick={handleTemporaryDismiss} sx={{ minWidth: 0 }}>
            <Close />
          </ListItemIcon>
        </ListItem>
      )}
      {children}
    </Drawer>
  );
}

const noOp = () => {};

export function NavigationMenu({
  $key,
  targetVariant,
  open,
  onDismiss: handleDismiss = () => {},
}: {
  $key: SidebarDrawerFragment$key | null | undefined;
  targetVariant: SidebarVariant;
  open: boolean;
  onDismiss?: () => void;
}) {
  const $data = useFragment(
    graphql`
      fragment SidebarDrawerFragment on Query {
        ...AuthorizationAdminFragment
        ...AuthorizationCraneChartMaintainerFragment
      }
    `,
    $key,
  );

  const [, animationDuration] = useSidebarAnimation(targetVariant, open, {}, []);
  const activeVariant = useSticky(targetVariant, animationDuration, (v) => v === 'permanent');
  const dashboardEnabled = useFlag('app_navigation_dashboard');
  const opportunitiesEnabled = useFlag('app_navigation_opportunities');
  const clientsEnabled = useFlag('app_navigation_clients');
  const worksitesEnabled = useFlag('app_navigation_worksites');
  const representativesEnabled = useFlag('app_navigation_representatives');
  const workPlanningsEnabled = useFlag('app_navigation_work_plannings');
  const quoteEnabled = useFlag('app_navigation_quote');
  const serviceCallsEnabled = useFlag('app_navigation_service_calls');
  const configurationEnabled = useFlag('app_navigation_configuration');
  const craneChartsEnabled = useFlag('app_navigation_crane_charts');

  const showCRM = dashboardEnabled || opportunitiesEnabled || clientsEnabled || worksitesEnabled || representativesEnabled;
  const showSales = workPlanningsEnabled || quoteEnabled || serviceCallsEnabled;
  const showConfiguration = configurationEnabled && activeVariant === 'permanent';
  const showCraneCharts = craneChartsEnabled && activeVariant === 'permanent' && $data;
  const showMaintenance = useFlag('app_navigation_maintenance');

  const { t } = useTranslation('layout');

  const workPlanningListPageUrl = useWorkPlanningListPageUrl();
  const quoteListPageUrl = useQuoteListPageUrl();
  const serviceCallListPageUrl = useServiceCallListPageUrl();

  const handleItemClick = activeVariant === 'temporary' ? handleDismiss : noOp;

  return (
    <List
      role='menu'
      sx={{
        // Initial padding is handled by section header.
        pt: 0,
        // Match height of the first section's header to provide a balanced overscroll at the end of the list.
        pb: sectionHeaderHeight,
      }}>
      {showCRM && (
        <NavigationMenuSection targetVariant={targetVariant} open={open} title={t('sidebar.crm')}>
          {dashboardEnabled && (
            <NavigationMenuLink to='/dashboard' icon={<DashboardIcon />} label={t('sidebar.dashboard')} onClick={handleItemClick} />
          )}
          {opportunitiesEnabled && (
            <NavigationMenuLink to='/opportunities' icon={<StarIcon />} label={t('sidebar.opportunities')} onClick={handleItemClick} />
          )}
          {clientsEnabled && (
            <NavigationMenuLink to='/clients' icon={<PersonIcon />} label={t('sidebar.clients')} onClick={handleItemClick} />
          )}
          {worksitesEnabled && (
            <NavigationMenuLink to='/worksites' icon={<PlaceIcon />} label={t('sidebar.worksites')} onClick={handleItemClick} />
          )}
          {representativesEnabled && (
            <NavigationMenuLink
              to='/representatives'
              icon={<ShoppingBagIcon />}
              label={t('sidebar.representatives')}
              onClick={handleItemClick}
            />
          )}
        </NavigationMenuSection>
      )}
      {showSales && (
        <NavigationMenuSection targetVariant={targetVariant} open={open} title={t('sidebar.sales')}>
          {workPlanningsEnabled && (
            <NavigationMenuLink
              to={workPlanningListPageUrl}
              icon={<DesignServicesIcon />}
              label={t('sidebar.workPlannings')}
              onClick={handleItemClick}
            />
          )}
          {quoteEnabled && (
            <NavigationMenuLink to={quoteListPageUrl} icon={<RequestQuoteIcon />} label={t('sidebar.quotes')} onClick={handleItemClick} />
          )}
          {serviceCallsEnabled && (
            <NavigationMenuLink
              to={serviceCallListPageUrl}
              icon={<CallIcon />}
              label={t('sidebar.serviceCalls')}
              onClick={handleItemClick}
            />
          )}
        </NavigationMenuSection>
      )}
      {(showConfiguration || showCraneCharts) && $data && (
        // Note: _all_ admin have the CraneChartMaintainer, so it is the lower common role
        <RequireCraneChartMaintainer $key={$data}>
          <NavigationMenuSection targetVariant={targetVariant} open={open} title={t('sidebar.configuration')}>
            {showConfiguration && (
              <RequireAdmin $key={$data}>
                <NavigationMenuLink
                  to='/configuration'
                  icon={<SettingsIcon />}
                  label={t('sidebar.configuration')}
                  onClick={handleItemClick}
                />
                <NavigationMenuLink
                  to='/billing-code-rules'
                  icon={<RuleIcon />}
                  label={t('sidebar.billingCodeRules')}
                  onClick={handleItemClick}
                />
              </RequireAdmin>
            )}
            {showCraneCharts && (
              <NavigationMenuLink to='/crane-charts' icon={<TableChart />} label={t('sidebar.craneCharts')} onClick={handleItemClick} />
            )}
          </NavigationMenuSection>
        </RequireCraneChartMaintainer>
      )}
      {showMaintenance && $data && (
        <RequireAdmin $key={$data}>
          <NavigationMenuLink to='/maintenance' icon={<BuildIcon />} label={t('sidebar.maintenance')} onClick={handleItemClick} />
        </RequireAdmin>
      )}
    </List>
  );
}

function NavigationMenuSection({
  targetVariant,
  open,
  title,
  children,
}: {
  targetVariant: SidebarVariant;
  open: boolean;
  title: string;
  children: ReactNode;
}) {
  const theme = useTheme();

  const [animationSx] = useSidebarAnimation(
    targetVariant,
    open,
    {
      narrow: { width: theme.mixins.drawer.width.narrow },
      wide: { width: theme.mixins.drawer.width.wide },
    },
    ['width'],
  );

  return (
    <ListItem disablePadding>
      <List
        disablePadding
        subheader={<NavigationMenuSectionTitle targetVariant={targetVariant} open={open} label={title} />}
        sx={{
          flexGrow: 1,
          ...animationSx,
        }}>
        {children}
      </List>
    </ListItem>
  );
}

const sectionHeaderHeight = '3rem';

function NavigationMenuSectionTitle({ targetVariant, open, label }: { targetVariant: SidebarVariant; open: boolean; label: string }) {
  const theme = useTheme();

  const [itemAnimationSx] = useSidebarAnimation(
    targetVariant,
    open,
    {
      dialog: { borderBottomColor: theme.palette.background.paper },
      none: { borderBottomColor: theme.palette.divider },
      narrow: { borderBottomColor: theme.palette.divider },
      wide: { borderBottomColor: theme.palette.background.paper },
    },
    ['border-bottom-color'],
  );
  const [titleAnimationSx] = useSidebarAnimation(
    targetVariant,
    open,
    {
      dialog: { color: theme.palette.text.primary },
      none: { color: 'transparent', transform: 'translateX(-2rem)' },
      narrow: { color: 'transparent', transform: 'translateX(-2rem)' },
      wide: { color: theme.palette.text.primary },
    },
    ['color', 'transform'],
  );

  return (
    <ListItem
      role='heading'
      sx={{
        height: sectionHeaderHeight,
        padding: '1rem 0 0 2rem',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        ...itemAnimationSx,
      }}>
      <Typography
        sx={{
          fontSize: '0.75rem',
          textTransform: 'uppercase',
          ...titleAnimationSx,
        }}>
        {label}
      </Typography>
    </ListItem>
  );
}

export function NavigationMenuLink({
  to,
  icon,
  label,
  onClick: handleClick,
}: {
  to: string;
  icon: JSX.Element;
  label: string;
  onClick: () => void;
}) {
  const theme = useTheme();
  // Ignore search params to support matching child routes with search params appended at the end of the url
  const routeMatch = useMatch(`${to.split('?')[0]}/*`);

  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function RenderLink(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role='link' onClick={handleClick} />;
      }),
    [handleClick, to],
  );

  return (
    <ListItem role='menuitem' disablePadding divider>
      <ListItemButton
        component={renderLink}
        selected={!!routeMatch}
        sx={{
          '&.Mui-selected': {
            borderRight: `0.25rem solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.background.paper,
          },
          '&.Mui-selected .MuiListItemText-root .MuiTypography-root': {
            fontWeight: 'bold',
          },
          '&.Mui-selected .MuiListItemIcon-root': {
            color: theme.palette.secondary.main,
          },
        }}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>
          <Typography>{label}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
