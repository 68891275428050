import {
  createFieldKey,
  FieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldSetter,
  useFieldValidation,
  useFieldValue,
} from '../../common/utils/forms';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ChangeEvent, Fragment, SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { jobStageBaseFormContext } from '../JobStageBaseFields';
import { useTranslation } from 'react-i18next';
import { QuestionsBaseFields_HasWorksiteBeenVisitedFragment$key } from './__generated__/QuestionsBaseFields_HasWorksiteBeenVisitedFragment.graphql';
import { castSalesQuestionKind, SalesQuestionKind } from '../../__enums__/SalesQuestionKind';
import { SelectPicker, SelectPickerProps } from '../../common/components/SelectPicker';
import { QuestionsBaseFields_SelectedQuestionKindsFragment$key } from './__generated__/QuestionsBaseFields_SelectedQuestionKindsFragment.graphql';
import { castPowerlineProximity, PowerlineProximity } from '../../__enums__/PowerlineProximity';
import { QuestionsBaseFields_ProximityToPowerlineFragment$key } from './__generated__/QuestionsBaseFields_ProximityToPowerlineFragment.graphql';
import { LengthInput } from '../../common/components/LengthInput';
import { Length, LengthUnit } from '../../common/utils/dimensions/length';
import { AngleInput } from '../../common/components/AngleInput';
import { Angle } from '../../common/utils/dimensions/angle';
import { QuestionsBaseFields_ExcavationLessThan10FeetFragment$key } from './__generated__/QuestionsBaseFields_ExcavationLessThan10FeetFragment.graphql';
import { QuestionsBaseFields_IsBerlinWallFragment$key } from './__generated__/QuestionsBaseFields_IsBerlinWallFragment.graphql';
import { QuestionsBaseFields_IsTilledSoilFragment$key } from './__generated__/QuestionsBaseFields_IsTilledSoilFragment.graphql';
import { QuestionsBaseFields_AngleFragment$key } from './__generated__/QuestionsBaseFields_AngleFragment.graphql';
import { QuestionsBaseFields_DistanceFragment$key } from './__generated__/QuestionsBaseFields_DistanceFragment.graphql';
import { RequiredForIcons, RequiredForInputLabel, RequiredForStatus } from '../RequiredForJobStatus';
import { JobStage } from '../jobStage';
import { sequenceEqual } from '../../common/utils/arrayUtils';

const fieldHasWorksiteBeenVisitedKey = createFieldKey<boolean | null>();

export function useFieldHasWorksiteBeenVisitedRead($key: QuestionsBaseFields_HasWorksiteBeenVisitedFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment QuestionsBaseFields_HasWorksiteBeenVisitedFragment on QuestionsInternalBase {
        hasWorksiteBeenVisited
      }
    `,
    $key,
  );
  const hasWorksiteBeenVisited = useFieldValue(
    jobStageBaseFormContext,
    fieldHasWorksiteBeenVisitedKey,
    () => $data?.hasWorksiteBeenVisited ?? null,
  );

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldHasWorksiteBeenVisitedKey);
  useMapper((v) => ({ questionsBase: { hasWorksiteBeenVisited: v } }), [], 'save');

  return { hasWorksiteBeenVisited };
}
export function useFieldHasWorksiteBeenVisited(
  $key: QuestionsBaseFields_HasWorksiteBeenVisitedFragment$key | null | undefined,
  disabled: boolean,
) {
  const { hasWorksiteBeenVisited, ...rest } = useFieldHasWorksiteBeenVisitedRead($key);
  const setHasWorksiteBeenVisited = useFieldSetter(jobStageBaseFormContext, fieldHasWorksiteBeenVisitedKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldHasWorksiteBeenVisitedKey);
  useValidation((v) => v != null, [], 'transferable,submittable:required');

  const renderHasWorksiteBeenVisited = useCallback(() => {
    return (
      <QuestionsRadioInput
        value={hasWorksiteBeenVisited}
        setValue={setHasWorksiteBeenVisited}
        labelKey='questionsBase.hasWorksiteBeenVisited'
        disabled={disabled}
        fieldKey={fieldHasWorksiteBeenVisitedKey}
      />
    );
  }, [hasWorksiteBeenVisited, setHasWorksiteBeenVisited, disabled]);

  return { hasWorksiteBeenVisited, setHasWorksiteBeenVisited, renderHasWorksiteBeenVisited, ...rest };
}

function QuestionsRadioInput({
  value,
  setValue,
  labelKey,
  disabled,
  fieldKey,
}: {
  value: boolean | null;
  setValue: SetValueFn<boolean | null>;
  labelKey: string;
  disabled: boolean;
  fieldKey: FieldKey<boolean | null>;
}) {
  const { t } = useTranslation('jobs');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldKey);
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, v: string) => {
      const boolValue = v === 'true';
      setValue(boolValue);
    },
    [setValue],
  );

  return (
    <FormControl key={labelKey} component='fieldset' error={hasErrors}>
      <FormLabel component='legend' sx={{ display: 'flex' }}>
        {t(labelKey)}
        &nbsp;
        <RequiredForIcons
          requiredFor={{
            quote: ['submit'],
            serviceCall: ['transfer'],
          }}
          disabled={disabled}
        />
      </FormLabel>
      <RadioGroup value={value} onChange={handleChange} row={!smallScreen} data-label-key={labelKey}>
        <FormControlLabel value={true} control={<Radio />} label={t('yesNo.yes', { ns: 'common' })} disabled={disabled} />
        <FormControlLabel value={false} control={<Radio />} label={t('yesNo.no', { ns: 'common' })} disabled={disabled} />
      </RadioGroup>
      {message && <FormHelperText error>{t(message, { ns: 'common', ...errorParams })}</FormHelperText>}
    </FormControl>
  );
}

const fieldSelectedQuestionKindsKey = createFieldKey<SalesQuestionKind[]>();
const fieldSelectedQuestionKindsFragment = graphql`
  fragment QuestionsBaseFields_SelectedQuestionKindsFragment on QuestionsInternalBase {
    ...QuestionsBaseFields_ProximityToPowerlineFragment
    ...QuestionsBaseFields_ExcavationLessThan10FeetFragment
    salesQuestions {
      kind
    }
  }
`;

export function useFieldSelectedQuestionKindsRead($key: QuestionsBaseFields_SelectedQuestionKindsFragment$key | null | undefined) {
  const $data = useFragment(fieldSelectedQuestionKindsFragment, $key);
  const selectedQuestionKinds = useFieldValue(
    jobStageBaseFormContext,
    fieldSelectedQuestionKindsKey,
    () => $data?.salesQuestions.map((x) => castSalesQuestionKind(x.kind)) ?? [],
  );
  const selectedQuestionKindsAreDirty = useFieldIsDirty(jobStageBaseFormContext, fieldSelectedQuestionKindsKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldSelectedQuestionKindsKey);
  useMapper(
    (v) => ({
      questionsBase: {
        saveSalesQuestions: v.map((kind) => ({ kind })),
      },
    }),
    [],
    'save',
  );

  return { selectedQuestionKinds, selectedQuestionKindsAreDirty };
}
export function useFieldSelectedQuestionKinds(
  $key: QuestionsBaseFields_SelectedQuestionKindsFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(fieldSelectedQuestionKindsFragment, $key);
  const { selectedQuestionKinds, ...rest } = useFieldSelectedQuestionKindsRead($key);
  const setSelectedQuestionKinds = useFieldSetter(jobStageBaseFormContext, fieldSelectedQuestionKindsKey);

  const { renderProximityToPowerline, renderExcavationLessThan10Feet } = useSelectedQuestionKindsRules(
    selectedQuestionKinds,
    $data,
    $data,
    disabled,
  );

  const renderSelectedQuestionKinds = useCallback(
    () => <SelectedQuestionKindsInput value={selectedQuestionKinds} setValue={setSelectedQuestionKinds} disabled={disabled} />,
    [disabled, selectedQuestionKinds, setSelectedQuestionKinds],
  );

  const renderSpecialKindsDetails = useCallback(
    () => (
      <>
        {selectedQuestionKinds.map((kind) => {
          switch (kind) {
            case 'powerlineProximity':
              return <Fragment key={kind}>{renderProximityToPowerline()}</Fragment>;
            case 'excavationProximityLessThan10Feet':
              return <Fragment key={kind}>{renderExcavationLessThan10Feet()}</Fragment>;
            default:
              return null;
          }
        })}
      </>
    ),
    [renderExcavationLessThan10Feet, renderProximityToPowerline, selectedQuestionKinds],
  );

  return {
    selectedQuestionKinds,
    setSelectedQuestionKinds,
    renderSelectedQuestionKinds,
    renderSpecialKindsDetails,
    ...rest,
  };
}

function useSelectedQuestionKindsRules(
  values: SalesQuestionKind[],
  proximity$key: QuestionsBaseFields_ProximityToPowerlineFragment$key | null | undefined,
  excavation$key: QuestionsBaseFields_ExcavationLessThan10FeetFragment$key | null | undefined,
  disabled: boolean,
) {
  const isDirty = useFieldIsDirty(jobStageBaseFormContext, fieldSelectedQuestionKindsKey);
  const lastValueRef = useRef(values);

  const { renderProximityToPowerline, setProximityToPowerline } = useFieldProximityToPowerline(proximity$key, disabled);
  const { renderExcavationLessThan10Feet, setExcavationLessThan10Feet } = useFieldExcavationLessThan10Feet(excavation$key, disabled);

  // Important note: The validation should only be run if the field is visible, aka the kinds includes the correct value
  // Note: This does not work currently for unknown reasons
  const angleValidation = useFieldValidation(jobStageBaseFormContext, fieldAngleKey);
  angleValidation((v) => !values.includes('excavationProximityLessThan10Feet') || v != null, [values], 'transferable,submittable:required');
  const distanceValidation = useFieldValidation(jobStageBaseFormContext, fieldDistanceKey);
  distanceValidation(
    (v) => !values.includes('excavationProximityLessThan10Feet') || v != null,
    [values],
    'transferable,submittable:required',
  );
  const proximityValidation = useFieldValidation(jobStageBaseFormContext, fieldProximityToPowerline);
  proximityValidation(
    (v) => !values.includes('powerlineProximity') || (v.option != null && v.option.trim().length > 0),
    [values],
    'transferable,submittable:required',
  );

  // When the kinds change, we need to reset the additional data related to those kinds
  useEffect(() => {
    const lastValue = [...lastValueRef.current].sort();
    const currentValues = [...values].sort();

    if (isDirty && !sequenceEqual(lastValue, currentValues)) {
      lastValueRef.current = values;
      if (!values.includes('excavationProximityLessThan10Feet')) {
        setExcavationLessThan10Feet({ angle: null, distance: null, isTilledSoil: false, isBerlinWallOrSupportedExcavation: false });
      }
      if (!values.includes('powerlineProximity')) {
        setProximityToPowerline({ option: null });
      }
    }
  }, [isDirty, values, setExcavationLessThan10Feet, setProximityToPowerline]);

  return { renderProximityToPowerline, renderExcavationLessThan10Feet };
}

// Used to group them under the "special projects" in the picker
export const specialProjects: SalesQuestionKind[] = [
  'accidentScene',
  'conventionalCrawlerCranesWithoutTravel',
  'conventionalCrawlerCranesWithTravel',
  'luffingJibOrExtensionInstallation',
  'movingCraneOrEquipmentWithoutPulling',
  'movingCraneOrEquipmentWithPulling',
  'tandemLiftingClientCrane',
  'tandemLiftingGuayCrane',
  'bargeWork',
  'unloadingOnQuay',
  'jettyBridgeViaductDamSlabOrParkingDeck',
  'windTurbines',
  'clamShellBucketOrGrapple',
  'events',
  'valueOf1MillionOrMore',
  'videoOrPhotoShooting',
];

// Ensures the order in a non-messy way
export const kindValues: SalesQuestionKind[] = [
  'peopleLifting',
  'hazardousTerrain',
  'powerlineProximity',
  'craneOrObstacleProximity',
  'excavationProximityMoreThan10Feet',
  'excavationProximityLessThan10Feet',
  ...specialProjects,
];

function SelectedQuestionKindsInput({
  value,
  setValue,
  disabled,
}: {
  value: SalesQuestionKind[];
  setValue: SetValueFn<SalesQuestionKind[]>;
  disabled: boolean;
}) {
  const { t } = useTranslation('jobs');

  const handleChange = useCallback<NonNullable<SelectPickerProps<SalesQuestionKind, true>['onChange']>>(
    (_, v) => {
      setValue(v.map((x) => x));
    },
    [setValue],
  );

  return (
    <SelectPicker
      multiple
      value={value}
      disabled={disabled}
      options={kindValues}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`salesQuestionKinds.${o}`, { ns: 'common' })}
      groupBy={(o) => (specialProjects.includes(o) ? t('questionsBase.specialProjects') : '')}
      onChange={handleChange}
      textFieldProps={() => ({
        label: t('questionsBase.specialSituation'),
      })}
    />
  );
}

const fieldAngleKey = createFieldKey<Angle | null>();
function useFieldAngle($key: QuestionsBaseFields_AngleFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment QuestionsBaseFields_AngleFragment on SalesQuestionForExcavationProximityLessThan10Feet {
        angle
      }
    `,
    $key,
  );

  const [angle, setAngle] = useField(jobStageBaseFormContext, fieldAngleKey, () => Angle.parse($data?.angle));
  const mapper = useFieldMapper(jobStageBaseFormContext, fieldAngleKey);
  mapper((v) => ({ questionsBase: { saveExcavationProximityLessThan10Feet: { angle: v?.toJSON() ?? null } } }), [], 'save');

  return { angle, setAngle };
}

const fieldDistanceKey = createFieldKey<Length | null>();
function useFieldDistance($key: QuestionsBaseFields_DistanceFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment QuestionsBaseFields_DistanceFragment on SalesQuestionForExcavationProximityLessThan10Feet {
        distance
      }
    `,
    $key,
  );

  const [distance, setDistance] = useField(jobStageBaseFormContext, fieldDistanceKey, () => Length.parse($data?.distance));
  const mapper = useFieldMapper(jobStageBaseFormContext, fieldDistanceKey);
  mapper((v) => ({ questionsBase: { saveExcavationProximityLessThan10Feet: { distance: v?.toJSON() ?? null } } }), [], 'save');

  return { distance, setDistance };
}

const fieldIsTilledSoilKey = createFieldKey<boolean>();
function useFieldIsTilledSoil($key: QuestionsBaseFields_IsTilledSoilFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment QuestionsBaseFields_IsTilledSoilFragment on SalesQuestionForExcavationProximityLessThan10Feet {
        isTilledSoil
      }
    `,
    $key,
  );

  const [isTilledSoil, setIsTilledSoil] = useField(jobStageBaseFormContext, fieldIsTilledSoilKey, () => $data?.isTilledSoil ?? false);
  const mapper = useFieldMapper(jobStageBaseFormContext, fieldIsTilledSoilKey);
  mapper((v) => ({ questionsBase: { saveExcavationProximityLessThan10Feet: { isTilledSoil: v } } }), [], 'save');

  return { isTilledSoil, setIsTilledSoil };
}

const fieldIsBerlinWallKey = createFieldKey<boolean>();
function useFieldIsBerlinWall($key: QuestionsBaseFields_IsBerlinWallFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment QuestionsBaseFields_IsBerlinWallFragment on SalesQuestionForExcavationProximityLessThan10Feet {
        isBerlinWallOrSupportedExcavation
      }
    `,
    $key,
  );

  const [isBerlinWall, setIsBerlinWall] = useField(
    jobStageBaseFormContext,
    fieldIsBerlinWallKey,
    () => $data?.isBerlinWallOrSupportedExcavation ?? false,
  );
  const mapper = useFieldMapper(jobStageBaseFormContext, fieldIsBerlinWallKey);
  mapper((v) => ({ questionsBase: { saveExcavationProximityLessThan10Feet: { isBerlinWallOrSupportedExcavation: v } } }), [], 'save');

  return { isBerlinWall, setIsBerlinWall };
}

type FieldProximityToPowerlineValue = { option: PowerlineProximity | null };
const fieldProximityToPowerline = createFieldKey<FieldProximityToPowerlineValue>();
function useFieldProximityToPowerline($key: QuestionsBaseFields_ProximityToPowerlineFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment QuestionsBaseFields_ProximityToPowerlineFragment on QuestionsInternalBase {
        salesQuestions {
          kind
          ... on SalesQuestionForPowerlineProximity {
            proximity
          }
        }
      }
    `,
    $key,
  )?.salesQuestions.find((x) => x.kind === 'powerlineProximity');

  const [proximityToPowerline, setProximityToPowerline] = useField(
    jobStageBaseFormContext,
    fieldProximityToPowerline,
    () =>
      ({
        option: $data?.proximity != null ? castPowerlineProximity($data.proximity) : null,
      }) satisfies FieldProximityToPowerlineValue,
  );

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldProximityToPowerline);
  useMapper(
    (v) => ({
      questionsBase: {
        saveProximityToPowerline: {
          proximity: v.option,
        },
      },
    }),
    [],
    'save',
  );

  const renderProximityToPowerline = useCallback(
    () => <ProximityToPowerlineInput value={proximityToPowerline} setValue={setProximityToPowerline} disabled={disabled} />,
    [disabled, proximityToPowerline, setProximityToPowerline],
  );

  return {
    proximityToPowerline,
    setProximityToPowerline,
    renderProximityToPowerline,
  };
}

// Ensures the order in a non-messy way
const proximityValues: PowerlineProximity[] = ['lessThan126KV', 'from126KVTo250KV', 'from251KVTo550KV', 'moreThan550KV'];

function ProximityToPowerlineInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldProximityToPowerlineValue;
  setValue: SetValueFn<FieldProximityToPowerlineValue>;
  disabled: boolean;
}) {
  const { t } = useTranslation('common');
  const handleChange = useCallback<NonNullable<SelectPickerProps<PowerlineProximity>['onChange']>>(
    (_, v) => {
      if (!v) return;
      setValue({ option: v });
    },
    [setValue],
  );

  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldProximityToPowerline);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <>
      <Typography py={1} sx={{ gridColumn: '1/-1' }}>
        {t('questionsBase.powerlineInformation', { ns: 'jobs' })}
      </Typography>
      <SelectPicker
        value={value.option}
        getOptionKey={(x) => x}
        options={proximityValues}
        getOptionLabel={(o) => t(`proximityToPowerline.${o}`)}
        disabled={disabled}
        onChange={handleChange}
        textFieldProps={() => ({
          label: <RequiredForInputLabel label={t('salesQuestionKinds.powerlineProximity')} requiredFor={requiredFor} disabled={disabled} />,
          error: hasErrors,
        })}
      />
    </>
  );
}

type FieldExcavationLessThan10FeetValue = {
  angle: Angle | null;
  distance: Length | null;
  isTilledSoil: boolean;
  isBerlinWallOrSupportedExcavation: boolean;
};
function useFieldExcavationLessThan10Feet(
  $key: QuestionsBaseFields_ExcavationLessThan10FeetFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment QuestionsBaseFields_ExcavationLessThan10FeetFragment on QuestionsInternalBase {
        salesQuestions {
          kind
          ... on SalesQuestionForExcavationProximityLessThan10Feet {
            ...QuestionsBaseFields_AngleFragment
            ...QuestionsBaseFields_IsBerlinWallFragment
            ...QuestionsBaseFields_DistanceFragment
            ...QuestionsBaseFields_IsTilledSoilFragment
          }
        }
      }
    `,
    $key,
  )?.salesQuestions.find((x) => x.kind === 'excavationProximityLessThan10Feet');

  const { angle, setAngle: handleAngleChange } = useFieldAngle($data);
  const { distance, setDistance: handleDistanceChange } = useFieldDistance($data);
  const { isTilledSoil, setIsTilledSoil: handleIsTilledSoilChange } = useFieldIsTilledSoil($data);
  const { isBerlinWall, setIsBerlinWall: handleIsBerlinWallChange } = useFieldIsBerlinWall($data);

  const setExcavationLessThan10Feet = useCallback(
    (value: FieldExcavationLessThan10FeetValue) => {
      handleAngleChange(value.angle);
      handleDistanceChange(value.distance);
      handleIsTilledSoilChange(value.isTilledSoil);
      handleIsBerlinWallChange(value.isBerlinWallOrSupportedExcavation);
    },
    [handleAngleChange, handleIsBerlinWallChange, handleDistanceChange, handleIsTilledSoilChange],
  );

  const renderExcavationLessThan10Feet = useCallback(
    () => (
      <ExcavationLessThan10FeetInput
        disabled={disabled}
        angle={angle}
        onAngleChange={handleAngleChange}
        distance={distance}
        onDistanceChange={handleDistanceChange}
        isBerlinWall={isBerlinWall}
        onIsBerlinWallChange={handleIsBerlinWallChange}
        isTilledSoil={isTilledSoil}
        onIsTilledSoilChange={handleIsTilledSoilChange}
      />
    ),
    [
      disabled,
      angle,
      handleAngleChange,
      distance,
      handleDistanceChange,
      isBerlinWall,
      handleIsBerlinWallChange,
      isTilledSoil,
      handleIsTilledSoilChange,
    ],
  );

  return {
    setExcavationLessThan10Feet,
    renderExcavationLessThan10Feet,
  };
}

const excavationLessThan10FeetEnabledDistanceUnits = ['ft', 'in', 'ftin', 'mm', 'cm', 'm'] as const satisfies LengthUnit[];
function ExcavationLessThan10FeetInput({
  disabled,
  angle,
  onAngleChange: handleAngleChange,
  distance,
  onDistanceChange: handleDistanceChange,
  isTilledSoil,
  onIsTilledSoilChange: handleIsTilledSoilChange,
  isBerlinWall,
  onIsBerlinWallChange: handleIsBerlinWallChange,
}: {
  disabled: boolean;
  angle: Angle | null;
  onAngleChange: (angle: Angle | null) => void;
  distance: Length | null;
  onDistanceChange: (distance: Length | null) => void;
  isTilledSoil: boolean;
  onIsTilledSoilChange: (isTilledSoil: boolean) => void;
  isBerlinWall: boolean;
  onIsBerlinWallChange: (isBerlinWall: boolean) => void;
}) {
  const { t } = useTranslation('jobs');

  const angleHasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldAngleKey);
  const distanceHasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldDistanceKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <>
      <Typography py={1} sx={{ gridColumn: '1/-1' }}>
        {t('questionsBase.excavationInformation')}
      </Typography>

      <AngleInput
        value={angle}
        label={<RequiredForInputLabel label={t('questionsBase.field.angle')} requiredFor={requiredFor} disabled={disabled} />}
        onChange={handleAngleChange}
        disabled={disabled}
        error={angleHasErrors}
        min={0}
        max={360}
      />
      <LengthInput
        enabledUnits={excavationLessThan10FeetEnabledDistanceUnits}
        value={distance}
        label={<RequiredForInputLabel label={t('questionsBase.field.distance')} requiredFor={requiredFor} disabled={disabled} />}
        onChange={handleDistanceChange}
        disabled={disabled}
        error={distanceHasErrors}
      />
      <QuestionsCheckboxInput
        value={isBerlinWall}
        onChange={handleIsBerlinWallChange}
        labelKey='questionsBase.field.isBerlinWall'
        disabled={disabled}
      />
      <QuestionsCheckboxInput
        value={isTilledSoil}
        onChange={handleIsTilledSoilChange}
        labelKey='questionsBase.field.isTilledSoil'
        disabled={disabled}
      />
    </>
  );
}

function QuestionsCheckboxInput({
  value,
  onChange,
  labelKey,
  disabled,
}: {
  value: boolean;
  onChange: (v: boolean) => void;
  labelKey: string;
  disabled: boolean;
}) {
  const { t } = useTranslation('jobs');

  const handleChange = useCallback((_: SyntheticEvent, v: boolean) => onChange(v), [onChange]);

  return (
    <FormControlLabel
      value={value}
      onChange={handleChange}
      checked={value}
      control={<Checkbox />}
      label={t(labelKey)}
      disabled={disabled}
    />
  );
}
