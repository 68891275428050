import { AddendumKind } from '../../__enums__/AddendumKind';
import { AttachmentKind } from '../../__enums__/AttachmentKind';
import { CancelReason } from '../../__enums__/CancelReason';
import { JobStage } from '../jobStage';

const addendumKind: Record<AddendumKind, string> = {
  archived: 'Archived',
  clientOrWorksite: 'Client / Worksite',
  craneOperator: 'Crane Operator',
  dispatching: 'Dispatching',
  invoicing: 'Invoicing',
  quote: 'Quote',
  internalNote: 'Internal note',
};

const alert = {
  addendaDependency: {
    title: 'Automatic Addenda',
    description: 'The following fields are missing to display automatic addenda:',
  },
  creditMessage: 'Credit message',
  billingRequirements: 'Billing requirements',
  costDependency: {
    title: 'Section Locked',
    description: 'The following fields are missing to edit this section:',
  },
};

const dialog = {
  cancel: {
    reason: 'Reason',
    comment: 'Comment',
  },
  copy: {
    accessories: 'Accessories',
    addenda: 'Addenda',
    attachments: 'Attachments',
    body: 'Do you want to copy the information?',
    newProject: 'New Projet',
    sameProject: 'Same Project',
  },
  filter: {
    representative: 'Customer Account Representative',
  },
};

const salesRate = {
  missingPrice: 'Missing price',
  minimumQuantity: 'Minimum quantity of {{quantity}}',
  unexpected: 'Unexpected error',
  manuallyEditedRate: 'Edited rate from {{rate}}',
};

export const jobs = {
  addendum: {
    deleteTitle: 'Delete Addendum',
    deleteMessage: 'Do you want to delete this addendum?',
  },
  addendumKind,
  alert,
  attachmentKind: {
    assemblySequence: 'Assembly sequence',
    bargeStabilityStudy: 'Barge stability study',
    bargeStowageInspection: 'Barge stowage inspection',
    certifications: 'Certifications',
    charters: 'Charters',
    competenceCards: 'Competence cards',
    engineeringCertificate: 'Engineering certificate',
    feasibilityStudy: 'Feasibility study',
    groundPressureCalculations: 'ST - Ground pressure calculations',
    insuranceDeclaration: 'Insurance declaration',
    liftingPlanOneCrane: 'ST - Lifting Plan 1 crane',
    liftingPlanReview: 'ST - Lifting Plan Review',
    liftingPlanTwoCranes: 'ST - Lifting Plan 2 cranes',
    listOfTransports: 'List of transports',
    location: 'Location',
    others: 'Others',
    parkingPermit: 'Parking permit',
    photos: 'Photos',
    projectDocuments: 'Project documents',
    roadSpecifications: 'ST - Road Specifications',
    signage: 'Signage',
    technicalDrawings: 'Technical drawings',
    transportPermit: 'Transport permit',
    travelSpecifications: 'ST - Travel specifications',
    workMethodEventSealedByEngineer: 'ST - Work method (event) sealed by engineer',
  } satisfies Record<AttachmentKind, string>,
  buttons: {
    consultRequirements: 'Consult',
    seeJobDocuments: 'Documents',
  },
  cancelReasons: {
    craneSelectionError: 'Crane selection error',
    deferredWorkLongTerm: 'Deferred work (long term)',
    duplicates: 'Duplicates',
    equipmentBreakdownGarage: 'Equipment breakdown (Garage)',
    forgotToCancel: 'Forgot to cancel',
    forgotToEnterServiceCall: 'Forgot to enter service call',
    forgotToSendCraneOperator: 'Forgot to send crane operator',
    lackOfEquipment: 'Lack of equipment',
    lostByCustomer: 'Lost by customer',
    lostCompetitor: 'Lost competitor',
    notTheRightEquipment: 'Not the right equipment',
    projectAbandoned: 'Project abandoned',
    withTheirCrane: 'With their crane',
    dispatch: 'Cancelled by dispatch',
  } satisfies Record<CancelReason, string>,
  crane: {
    additional: 'Additional {{ index }}',
    additionalShort: 'A{{ index }}',
    main: 'Main',
    mainShort: 'P', // short label stays P to standardize with French version
    tooltips: {
      angleRotation: 'Angle Rotation',
      foldable: 'Folding Jib',
      fixedLattice: 'Fixed Lattice Jib',
      variableSpanLattice: 'Variable Flight Lattice Jib',
      stabilizerExtension: 'Stabilizer Extension Percentage',
      superstructureCounterweightRadius: 'Superstructure Counterweight Radius',
      eccentricAxis: 'Eccentric Axis',
      usagePercentage: 'Usage Percentage',
      capacity: 'Configuration Capacity',
    },
  },
  creditCategory: {
    forcedCategoryTooltip: 'Original score: {{ category }}',
  },
  details: {
    section: {
      attachments: 'Attachments',
      jobProgression: 'Project Documents',
    },
  },
  dialog,
  error: {
    duringUpload: 'An error occurred during the upload',
    duringDownload: 'An error occurred during the download',
    unexpected: 'An unexpected error occurred. Please try again.',
  },
  field: {
    attachments: {
      empty: 'No attachments',
      kind: 'Kind',
      name: 'Name',
      upload: {
        state: {
          queued: 'Queued for upload',
          uploading: 'Uploading',
        },
      },
    },
    project: {
      schedulePeriod: {
        startTime: 'Start',
        endTime: 'End',
      },
    },
    equipment: {
      transport: {
        branchToWorksiteDistance: 'Branch to worksite distance',
      },
    },
  },
  labels: {
    lastUpdate: 'Last updated',
  },
  pages: {
    attachments: {
      title: 'Attachment',
    },
  },
  questionsBase: {
    hasWorksiteBeenVisited: 'Has a worksite visit been done?',
    selectAllSituations: 'Please select all applicable situations:',
    specialSituation: 'Particular Situations',
    excavationInformation: 'Information of the excavation',
    powerlineInformation: 'Information of the power lines',
    specialProjects: 'Special Projects',
    warning: {
      worksiteNotVisited: 'Warning: risk to be identified - Worksite not visited by representative',
    },
    field: {
      angle: 'Angle',
      distance: 'Distance',
      isTilledSoil: 'Is Tilled Soil?',
      isBerlinWall: 'Is Berlin Wall Or Supported Excavation?',
    },
  },
  requiredFor: {
    quote: {
      submit: 'Required to submit the quote',
    },
    serviceCall: {
      reserve: 'Required to reserve the call',
      transfer: 'Required to transfer the call',
    },
  },
  revisionNames: {
    ServiceCall: 'Service Call',
    Quote: 'Quote',
    WorkPlanning: 'Work Planning',
  },
  salesRate,
  jobStages: {
    workPlanning: 'Work Planning',
    quote: 'Quote',
    serviceCall: 'Service Call',
  } satisfies Record<JobStage, string>,
  selectKind: 'Select Kind',
};
