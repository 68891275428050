/**
 * @generated SignedSource<<506330b2f9268f96d98cde65a80c102a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SyncableEntity = "accessoryGroup" | "accessoryType" | "billing" | "billingSection" | "boomConfiguration" | "boomConfigurationAdditional" | "boomConfigurationTransport" | "boomConfigurationWorkForce" | "branch" | "client" | "configurationType" | "craneConfiguration" | "craneConfigurationEscort" | "craneConfigurationFlatbed" | "craneConfigurationPermit" | "descriptorType" | "employee" | "equipment" | "escortType" | "flatbedType" | "permitType" | "region" | "representative" | "requirement" | "statutoryHoliday" | "subRegion" | "surcharge" | "transportType" | "workForceType" | "workSite" | "%future added value";
export type ResyncOptionsInput = {
  readAs400: boolean;
  withDeleted: boolean;
  withRelation: boolean;
};
export type TabDataSyncResyncMutation$variables = {
  entity: SyncableEntity;
  options: ResyncOptionsInput;
};
export type TabDataSyncResyncMutation$data = {
  readonly resyncEntity: {
    readonly boolean: boolean | null | undefined;
  };
};
export type TabDataSyncResyncMutation = {
  response: TabDataSyncResyncMutation$data;
  variables: TabDataSyncResyncMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entity"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "options"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "entity",
            "variableName": "entity"
          },
          {
            "kind": "Variable",
            "name": "options",
            "variableName": "options"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "ResyncEntityPayload",
    "kind": "LinkedField",
    "name": "resyncEntity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TabDataSyncResyncMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TabDataSyncResyncMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7d2b48d16cfe9ed82ba744ebf4dfdd71",
    "id": null,
    "metadata": {},
    "name": "TabDataSyncResyncMutation",
    "operationKind": "mutation",
    "text": "mutation TabDataSyncResyncMutation(\n  $entity: SyncableEntity!\n  $options: ResyncOptionsInput!\n) {\n  resyncEntity(input: {entity: $entity, options: $options}) {\n    boolean\n  }\n}\n"
  }
};
})();

(node as any).hash = "56afd1784a2d0a60e3fdb4a632ed2671";

export default node;
