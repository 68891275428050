import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldSetter,
  useFieldValidation,
  useFieldValue,
} from '../../common/utils/forms';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ChangeEvent, ReactNode, SetStateAction, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { Chip, IconButton, TextField } from '@mui/material';
import { AddressInputGooglePlaces } from '../../common/components/AddressInputGooglePlaces';
import { MaskInput } from '../../common/components/MaskInput';
import {
  ClientRequirementAutocomplete,
  ForwardClientRequirementAutocompleteProps,
} from '../../common/components/ClientRequirementAutocomplete';
import { exclude } from '../../common/utils/arrayUtils';
import InfoIcon from '@mui/icons-material/Info';
import { RequirementsDialog } from '../../common/dialogs/RequirementsDialog';
import { ClientBaseFields_ClientOverrides_IsDirectSalesFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_IsDirectSalesFragment.graphql';
import { ClientBaseFields_ClientOverrides_NameFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_NameFragment.graphql';
import { ClientBaseFields_ClientOverrides_LocationFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_LocationFragment.graphql';
import { ClientBaseFields_ClientOverrides_PhoneNumberFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_PhoneNumberFragment.graphql';
import { ClientBaseFields_RequirementsFragment$key } from './__generated__/ClientBaseFields_RequirementsFragment.graphql';
import { jobStageBaseFormContext } from '../JobStageBaseFields';
import { useSuggestions } from '../useSuggestions';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { SuggestionPromptInput } from '../../common/components/__generated__/SuggestionsFakeQuery.graphql';
import { ClientBaseFields_ClientRequirementsSuggestionsFragment$key } from './__generated__/ClientBaseFields_ClientRequirementsSuggestionsFragment.graphql';
import { ClientBaseFields_ClientOverrides_LanguageCodeFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_LanguageCodeFragment.graphql';
import { SelectPicker } from '../../common/components/SelectPicker';
import { ConfirmationDialog } from '../../common/dialogs/ConfirmationDialog';
import { SalesDetails_useBillingCodeSearchInput_ClientFragment$key } from '../__generated__/SalesDetails_useBillingCodeSearchInput_ClientFragment.graphql';
import { castClientLanguage, ClientLanguage, clientLanguages } from '../../__enums__/ClientLanguage';
import { _throw } from '../../common/utils/_throw';
import { ClientBaseFields_useFieldAssignedClientFragment$key } from './__generated__/ClientBaseFields_useFieldAssignedClientFragment.graphql';
import { ForwardRepresentativeAutocompleteProps, RepresentativeAutocomplete } from '../../common/components/RepresentativeAutocomplete';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { InactiveStartAdornment } from '../../common/InactiveStartAdornment';
import { ClientBaseFields_RepresentativeFragment$key } from './__generated__/ClientBaseFields_RepresentativeFragment.graphql';
import { ClientBaseFields_ClientRepresentativeSuggestedFragment$key } from './__generated__/ClientBaseFields_ClientRepresentativeSuggestedFragment.graphql';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { RequiredForInputLabel, RequiredForStatus } from '../RequiredForJobStatus';
import { JobStage } from '../jobStage';

export type FieldAssignedClient = {
  id: DataID;
  etag: string;
  useBillingCodeSearchInput$key: SalesDetails_useBillingCodeSearchInput_ClientFragment$key | null | undefined;
} | null;
const fieldAssignedClientKey = createFieldKey<FieldAssignedClient>();

export function useFieldAssignedClientRead($key: ClientBaseFields_useFieldAssignedClientFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_useFieldAssignedClientFragment on ClientInternalBase {
        assignedClientId
        assignedClient {
          ...SalesDetails_useBillingCodeSearchInput_ClientFragment
        }
      }
    `,
    $key,
  );
  const assignedClient = useFieldValue(jobStageBaseFormContext, fieldAssignedClientKey, () =>
    !$data?.assignedClientId ? null : { id: $data.assignedClientId, etag: nanoid(), useBillingCodeSearchInput$key: $data.assignedClient },
  );
  const assignedClientIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldAssignedClientKey);
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldAssignedClientKey);
  useMapper((v) => ({ clientBase: { assignedClientId: v?.id ?? null } }), [], 'save');
  useMapper((v) => ({ salesRatesInput: { clientId: v?.id ?? _throw('Assigned client is required to recalculate') } }), [], 'recalculate');

  return { assignedClient, assignedClientIsDirty };
}
export function useFieldAssignedClient($key: ClientBaseFields_useFieldAssignedClientFragment$key | null | undefined) {
  const setAssignedClient = useFieldSetter(jobStageBaseFormContext, fieldAssignedClientKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldAssignedClientKey);
  useValidation((val) => val != null || 'error.clientIsRequired', [], 'transferable,submittable:required');

  return { setAssignedClient, ...useFieldAssignedClientRead($key) };
}

export function useFieldAssignedClientErrors() {
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldAssignedClientKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldAssignedClientKey);

  return { assignedClientErrorMessage: message, assignedClientErrorParams: errorParams, assignedClientHasErrors: hasErrors };
}

const fieldClientOverridesIsDirectSalesKey = createFieldKey<boolean>();

export function useFieldClientOverridesIsDirectSalesRead(
  $key: ClientBaseFields_ClientOverrides_IsDirectSalesFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_IsDirectSalesFragment on IOverridableClient {
        isDirectSales
      }
    `,
    $key,
  );
  const clientOverridesIsDirectSales = useFieldValue(
    jobStageBaseFormContext,
    fieldClientOverridesIsDirectSalesKey,
    () => $data?.isDirectSales ?? false,
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesIsDirectSalesKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { isDirectSales: v } } }), [], 'save');

  return { clientOverridesIsDirectSales };
}
export function useFieldClientOverridesIsDirectSales($key: ClientBaseFields_ClientOverrides_IsDirectSalesFragment$key | null | undefined) {
  const rest = useFieldClientOverridesIsDirectSalesRead($key);
  const setClientOverridesIsDirectSales = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesIsDirectSalesKey);

  return { setClientOverridesIsDirectSales, ...rest };
}

const fieldClientOverridesNameKey = createFieldKey<string>();

export function useFieldClientOverridesNameRead($key: ClientBaseFields_ClientOverrides_NameFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_NameFragment on IOverridableClient {
        name
      }
    `,
    $key,
  );
  const clientOverridesName = useFieldValue(jobStageBaseFormContext, fieldClientOverridesNameKey, () => $data?.name ?? '');
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesNameKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { name: v } } }), [], 'save');

  return { clientOverridesName };
}
export function useFieldClientOverridesName(
  $key: ClientBaseFields_ClientOverrides_NameFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { clientOverridesName, ...rest } = useFieldClientOverridesNameRead($key);
  const setClientOverridesName = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesNameKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldClientOverridesNameKey);
  useValidation((v) => (required ? !!v && v.trim().length > 0 : true), [required], 'transferable,submittable:required');

  const renderClientOverridesName = useCallback(
    () => <ClientNameInput value={clientOverridesName} setValue={setClientOverridesName} disabled={disabled} />,
    [clientOverridesName, disabled, setClientOverridesName],
  );

  return { clientOverridesName, setClientOverridesName, renderClientOverridesName, ...rest };
}

function ClientNameInput({ value, setValue, disabled }: { value: string; setValue: SetValueFn<string>; disabled: boolean }) {
  const { t } = useTranslation('client');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldClientOverridesNameKey);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <TextField
      label={<RequiredForInputLabel label={t('field.name')} requiredFor={requiredFor} disabled={disabled} />}
      value={value}
      onChange={handleChange}
      onBlur={(e) => setValue(e.target.value.trim())}
      error={hasErrors}
      disabled={disabled}
    />
  );
}

type ClientOverridesLocationFieldType = { address: string; placeToken: { id: string | null; placeId: string } | null };
const fieldClientOverridesLocationKey = createFieldKey<ClientOverridesLocationFieldType>();

export function useFieldClientOverridesLocationRead($key: ClientBaseFields_ClientOverrides_LocationFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_LocationFragment on IOverridableClient {
        location {
          address
          placeToken {
            id
            placeId
          }
        }
      }
    `,
    $key,
  );
  const clientOverridesLocation = useFieldValue(jobStageBaseFormContext, fieldClientOverridesLocationKey, () => ({
    address: $data?.location?.address ?? '',
    placeToken: $data?.location?.placeToken ?? null,
  }));
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesLocationKey);
  useMapper(
    (v) => ({
      clientBase: {
        assignedClientInfo: {
          location: v
            ? {
                address: v.address,
                placeId: v.placeToken?.placeId ?? null,
              }
            : null,
        },
      },
    }),
    [],
    'save',
  );

  return { clientOverridesLocation };
}
export function useFieldClientOverridesLocation(
  $key: ClientBaseFields_ClientOverrides_LocationFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { clientOverridesLocation, ...rest } = useFieldClientOverridesLocationRead($key);
  const setClientOverridesLocation = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesLocationKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldClientOverridesLocationKey);
  useValidation((v) => (required ? !!v.address && v.address.trim().length > 0 : true), [required], 'transferable,submittable:required');

  const renderClientOverridesLocation = useCallback(
    () => <ClientLocationInput value={clientOverridesLocation} setValue={setClientOverridesLocation} disabled={disabled} />,
    [clientOverridesLocation, disabled, setClientOverridesLocation],
  );

  return { clientOverridesLocation, setClientOverridesLocation, renderClientOverridesLocation, ...rest };
}

function ClientLocationInput({
  value,
  setValue,
  disabled,
}: {
  value: ClientOverridesLocationFieldType;
  setValue: SetValueFn<ClientOverridesLocationFieldType>;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldClientOverridesLocationKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <AddressInputGooglePlaces
      value={value.address}
      onChange={(newValue) =>
        setValue((prev) =>
          typeof newValue === 'string' || newValue === null
            ? { ...prev, address: newValue ?? '', placeToken: null }
            : { ...prev, address: newValue.description, placeToken: { id: null, placeId: newValue.placeId } },
        )
      }
      placeId={value.placeToken?.placeId ?? null}
      placeTokenId={value.placeToken?.id ?? null}
      error={hasErrors}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: <RequiredForInputLabel label={t('field.address')} requiredFor={requiredFor} disabled={disabled} />,
        onBlur: (e) => {
          setValue((prev) => ({ ...prev, address: e.target.value.trim() }));
        },
        inputProps: { ...params.inputProps, maxLength: 200 },
        InputProps: {
          error: hasErrors,
          ...params.InputProps,
        },
      })}
    />
  );
}

const fieldClientOverridesPhoneNumberKey = createFieldKey<string>();

export function useFieldClientOverridesPhoneNumber(
  $key: ClientBaseFields_ClientOverrides_PhoneNumberFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_PhoneNumberFragment on IOverridableClient {
        phoneNumber
      }
    `,
    $key,
  );

  const [clientOverridesPhoneNumber, setClientOverridesPhoneNumber] = useField(
    jobStageBaseFormContext,
    fieldClientOverridesPhoneNumberKey,
    () => $data?.phoneNumber ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesPhoneNumberKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { phoneNumber: v } } }), [], 'save');

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldClientOverridesPhoneNumberKey);
  useValidation((v) => (required ? !!v : true), [required], 'transferable,submittable:required');

  const renderClientOverridesPhoneNumber = useCallback(
    () => <ClientPhoneNumberInput value={clientOverridesPhoneNumber} setValue={setClientOverridesPhoneNumber} disabled={disabled} />,
    [clientOverridesPhoneNumber, disabled, setClientOverridesPhoneNumber],
  );

  return { clientOverridesPhoneNumber, setClientOverridesPhoneNumber, renderClientOverridesPhoneNumber };
}

function ClientPhoneNumberInput({ value, setValue, disabled }: { value: string; setValue: SetValueFn<string>; disabled: boolean }) {
  const { t } = useTranslation('client');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldClientOverridesPhoneNumberKey);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <TextField
      label={<RequiredForInputLabel label={t('field.phoneNumber')} requiredFor={requiredFor} disabled={disabled} />}
      value={value}
      onChange={handleChange}
      error={hasErrors}
      disabled={disabled}
      InputProps={{
        inputComponent: MaskInput,
        inputProps: {
          error: hasErrors,
          mask: '000-000-0000',
        },
      }}
    />
  );
}

type useFieldClientOverridesRequirementsReturns = {
  clientOverridesRequirements: ForwardClientRequirementAutocompleteProps<true>['value'];
  setClientOverridesRequirements: (
    newValue: SetStateAction<ForwardClientRequirementAutocompleteProps<true>['value']>,
    dirty?: false,
  ) => void;
  renderClientOverridesRequirements: (
    input$key: ClientBaseFields_ClientRequirementsSuggestionsFragment$key | null | undefined,
    saleKind: ServiceCallKind,
  ) => ReactNode;
  renderClientOverridesRequirementsNoSuggestions: () => ReactNode;
};
const fieldClientOverridesRequirementsKey = createFieldKey<ForwardClientRequirementAutocompleteProps<true>['value']>();
function equalsById(left: { id: DataID }, right: { id: DataID }) {
  return left.id === right.id;
}
const fieldClientOverridesRequirementsFragment = graphql`
  fragment ClientBaseFields_RequirementsFragment on Requirement @relay(plural: true) {
    id
    label
  }
`;

export function useFieldClientOverridesRequirementsRead(
  $key: ClientBaseFields_RequirementsFragment$key | null | undefined,
  static$key: ClientBaseFields_RequirementsFragment$key | null | undefined,
) {
  const $data = useFragment(fieldClientOverridesRequirementsFragment, $key);
  const static$data = useFragment(fieldClientOverridesRequirementsFragment, static$key);
  const clientOverridesRequirements = useFieldValue(jobStageBaseFormContext, fieldClientOverridesRequirementsKey, () =>
    exclude($data ?? [], static$data ?? [], equalsById),
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesRequirementsKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { requirements: v ? { ids: v.map((r) => r.id) } : null } } }), [], 'save');

  return { clientOverridesRequirements };
}
export function useFieldClientOverridesRequirements(
  $key: ClientBaseFields_RequirementsFragment$key | null | undefined,
  static$key: ClientBaseFields_RequirementsFragment$key | null | undefined,
  disabled: boolean,
): useFieldClientOverridesRequirementsReturns {
  const static$data = useFragment(fieldClientOverridesRequirementsFragment, static$key);

  const { clientOverridesRequirements, ...rest } = useFieldClientOverridesRequirementsRead($key, static$key);
  const setClientOverridesRequirementsInternal = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesRequirementsKey);

  const setClientOverridesRequirements = useCallback<typeof setClientOverridesRequirementsInternal>(
    (newValue) => {
      if (typeof newValue === 'function') {
        setClientOverridesRequirementsInternal((old) => exclude(newValue(old) ?? [], static$data ?? [], equalsById));
      } else {
        setClientOverridesRequirementsInternal(exclude(newValue ?? [], static$data ?? [], equalsById));
      }
    },
    [setClientOverridesRequirementsInternal, static$data],
  );

  const displayedRequirements = useMemo(
    () => [...(static$data ?? []), ...(clientOverridesRequirements ?? [])],
    [clientOverridesRequirements, static$data],
  );

  const renderClientOverridesRequirements = useCallback(
    (input$key: ClientBaseFields_ClientRequirementsSuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => (
      <ClientRequirementsSuggested
        $key={input$key}
        value={displayedRequirements}
        setValue={setClientOverridesRequirements}
        saleKind={saleKind}
        disabled={disabled}
        staticRequirements={static$data ?? []}
      />
    ),
    [disabled, displayedRequirements, setClientOverridesRequirements, static$data],
  );

  const renderClientOverridesRequirementsNoSuggestions = useCallback(
    () => (
      <ClientRequirementsInput
        value={displayedRequirements}
        setValue={setClientOverridesRequirements}
        disabled={disabled}
        staticRequirements={static$data ?? []}
        suggestionPromptInput={null}
      />
    ),
    [disabled, displayedRequirements, setClientOverridesRequirements, static$data],
  );

  return {
    clientOverridesRequirements,
    setClientOverridesRequirements,
    renderClientOverridesRequirements,
    renderClientOverridesRequirementsNoSuggestions,
    ...rest,
  };
}

function ClientRequirementsSuggested({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
  staticRequirements,
}: {
  $key: ClientBaseFields_ClientRequirementsSuggestionsFragment$key | null | undefined;
  value: ForwardClientRequirementAutocompleteProps<true>['value'];
  setValue: SetValueFn<ForwardClientRequirementAutocompleteProps<true>['value']>;
  saleKind: ServiceCallKind;
  disabled: boolean;
  staticRequirements: NonNullable<ForwardClientRequirementAutocompleteProps<true>['value']>;
}) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientRequirementsSuggestionsFragment on ISale @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  return (
    <ClientRequirementsInput
      value={value}
      setValue={setValue}
      disabled={disabled}
      staticRequirements={staticRequirements}
      suggestionPromptInput={suggestionPromptInput}
    />
  );
}

function ClientRequirementsInput({
  value,
  setValue,
  disabled,
  staticRequirements,
  suggestionPromptInput,
}: {
  value: ForwardClientRequirementAutocompleteProps<true>['value'];
  setValue: SetValueFn<ForwardClientRequirementAutocompleteProps<true>['value']>;
  disabled: boolean;
  staticRequirements: NonNullable<ForwardClientRequirementAutocompleteProps<true>['value']>;
  suggestionPromptInput: SuggestionPromptInput | null;
}) {
  const { t } = useTranslation('client');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = useCallback(
    (values: ForwardClientRequirementAutocompleteProps<true>['value']) => {
      // we need to strip static requirements from results since they are not really part of the value.
      setValue(values?.filter((v) => !staticRequirements.some((sr) => sr.id === v.id)));
    },
    [setValue, staticRequirements],
  );

  return (
    <>
      <ClientRequirementAutocomplete
        multiple
        value={value}
        onChange={handleChange}
        suggestionPromptInput={suggestionPromptInput}
        suggestible={!!suggestionPromptInput}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const isDefaultRequirement = staticRequirements.some((r) => r.id === option.id);
            const handleDelete = isDefaultRequirement ? undefined : getTagProps({ index }).onDelete;
            return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' disabled={disabled} />;
          })
        }
        getOptionDisabled={(option) => staticRequirements.some((r) => r.id === option.id)}
        textFieldProps={(params) => ({
          label: t('field.requirements', { ns: 'common' }),
          InputProps: {
            ...params.InputProps,
            startAdornment: (
              <>
                {value && value.length > 0 && (
                  <IconButton onClick={() => setDialogOpen(true)} sx={{ position: 'absolute', left: 0, top: 'calc(50% - 1.25rem)' }}>
                    <InfoIcon sx={(theme) => ({ color: theme.palette.grey.A400 })} />
                  </IconButton>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
          },
        })}
        sx={value && value.length > 0 ? { '.MuiAutocomplete-inputRoot.MuiInputBase-adornedStart': { pl: '2.5rem' } } : {}}
        disabled={disabled}
      />
      <RequirementsDialog
        requirementIds={value?.map((r) => r.id) ?? []}
        onClose={() => setDialogOpen(false)}
        isOpen={dialogOpen}
        title={t('dialog.requirementsTitle', { ns: 'client' })}
      />
    </>
  );
}

const fieldClientOverridesLanguageCodeKey = createFieldKey<ClientLanguage | null>();

export function useFieldClientOverridesLanguageCodeRead(
  $key: ClientBaseFields_ClientOverrides_LanguageCodeFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_LanguageCodeFragment on IOverridableClient {
        language
      }
    `,
    $key,
  );
  const languageCode = useFieldValue(jobStageBaseFormContext, fieldClientOverridesLanguageCodeKey, () =>
    $data?.language ? castClientLanguage($data.language) : null,
  );
  const clientOverridesLanguageCodeIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldClientOverridesLanguageCodeKey);
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesLanguageCodeKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { language: v } } }), [], 'save');

  return { clientOverridesLanguageCode: languageCode, clientOverridesLanguageCodeIsDirty };
}
export function useFieldClientOverridesLanguageCode(
  $key: ClientBaseFields_ClientOverrides_LanguageCodeFragment$key | null | undefined,
  disabled: boolean,
) {
  const { clientOverridesLanguageCode, ...rest } = useFieldClientOverridesLanguageCodeRead($key);
  const setLanguageCode = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesLanguageCodeKey);

  const renderClientOverridesLanguageCode = useCallback(
    () => <ClientLanguageInput value={clientOverridesLanguageCode} setValue={setLanguageCode} disabled={disabled} />,
    [clientOverridesLanguageCode, disabled, setLanguageCode],
  );

  return {
    clientOverridesLanguageCode,
    setClientOverridesLanguageCode: setLanguageCode,
    renderClientOverridesLanguageCode,
    ...rest,
  };
}

function ClientLanguageInput({
  value,
  setValue,
  disabled,
}: {
  value: ClientLanguage | null;
  setValue: SetValueFn<ClientLanguage | null>;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');
  const [newValue, setNewValue] = useState<ClientLanguage | null>(null);
  const handleChange = useCallback((_: SyntheticEvent, v: ClientLanguage) => setNewValue(v), []);

  const handleConfirm = useCallback(() => {
    if (newValue) {
      setValue(newValue);
    }
    setNewValue(null);
  }, [newValue, setValue]);

  const handleCancel = useCallback(() => setNewValue(null), []);

  return (
    <>
      <SelectPicker
        value={value ?? 'fr'}
        disableClearable
        getOptionKey={(o) => o}
        getOptionLabel={(o) => t(`language.${o}`, { ns: 'client' })}
        onChange={handleChange}
        options={clientLanguages}
        disabled={disabled}
        textFieldProps={(params) => ({
          ...params,
          label: t('field.preferredLanguage'),
        })}
      />
      <ConfirmationDialog
        title={t('warning', { ns: 'common' })}
        message={t('placeholder.changeLanguageWarning')}
        onConfirm={handleConfirm}
        showClose={false}
        onCancel={handleCancel}
        isOpen={!!newValue}
      />
    </>
  );
}

type FieldRepresentative = ForwardRepresentativeAutocompleteProps['value'];
const fieldRepresentativeKey = createFieldKey<FieldRepresentative>();

export function useFieldRepresentativeRead($key: ClientBaseFields_RepresentativeFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_RepresentativeFragment on ClientInternalBase {
        projectManager {
          id
          label
          deletedAt
        }
      }
    `,
    $key,
  );
  const representative = useFieldValue(jobStageBaseFormContext, fieldRepresentativeKey, () => $data?.projectManager ?? null);
  const isRepresentativeDirty = useFieldIsDirty(jobStageBaseFormContext, fieldRepresentativeKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldRepresentativeKey);
  useMapper((v) => ({ clientBase: { projectManagerId: v?.id ?? null } }), [], 'save');

  return { representative, isRepresentativeDirty };
}
export function useFieldRepresentative($key: ClientBaseFields_RepresentativeFragment$key | null | undefined, disabled: boolean) {
  const { representative, ...rest } = useFieldRepresentativeRead($key);
  const setRepresentative = useFieldSetter(jobStageBaseFormContext, fieldRepresentativeKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldRepresentativeKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const renderRepresentative = useCallback(
    (input$key: ClientBaseFields_ClientRepresentativeSuggestedFragment$key | null | undefined, saleKind: QuoteKind | ServiceCallKind) => (
      <ClientRepresentativeSuggested
        $key={input$key}
        value={representative}
        setValue={setRepresentative}
        saleKind={saleKind}
        disabled={disabled}
      />
    ),
    [representative, setRepresentative, disabled],
  );

  const renderRepresentativeNoSuggestions = useCallback(
    () => (
      <ClientRepresentativeInput value={representative} setValue={setRepresentative} disabled={disabled} suggestionPromptInput={null} />
    ),
    [representative, setRepresentative, disabled],
  );

  return { representative, setRepresentative, renderRepresentative, renderRepresentativeNoSuggestions, ...rest };
}
function ClientRepresentativeSuggested({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: ClientBaseFields_ClientRepresentativeSuggestedFragment$key | null | undefined;
  value: FieldRepresentative;
  setValue: SetValueFn<FieldRepresentative>;
  saleKind: QuoteKind | ServiceCallKind;
  disabled: boolean;
}) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientRepresentativeSuggestedFragment on ISale @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );
  const suggestionPromptInput = useSuggestions($data, saleKind);

  return (
    <ClientRepresentativeInput
      value={value}
      setValue={setValue}
      disabled={disabled}
      suggestible
      suggestionPromptInput={suggestionPromptInput}
    />
  );
}
function ClientRepresentativeInput({
  value,
  setValue,
  disabled,
  suggestionPromptInput,
  suggestible,
}: {
  value: FieldRepresentative;
  setValue: SetValueFn<FieldRepresentative>;
  disabled: boolean;
  suggestionPromptInput: SuggestionPromptInput | null;
  suggestible?: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldRepresentativeKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldRepresentativeKey);

  const handleChange = useCallback<NonNullable<ForwardRepresentativeAutocompleteProps['onChange']>>((v) => setValue(v), [setValue]);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <RepresentativeAutocomplete
      value={value}
      onChange={handleChange}
      disabled={disabled}
      suggestionPromptInput={suggestionPromptInput}
      suggestible={suggestible}
      textFieldProps={(params) => ({
        label: <RequiredForInputLabel label={t('field.client.projectManager')} requiredFor={requiredFor} disabled={disabled} />,
        'data-label-key': 'field.client.projectManager',
        InputProps: {
          ...params.InputProps,
          startAdornment: (
            <>
              {value?.deletedAt && <InactiveStartAdornment />}
              {params.InputProps.startAdornment}
            </>
          ),
        },
        error: hasErrors,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
      })}
    />
  );
}
